import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLanguagePreferenceForm } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-language-preference-form';
import { BaseFormProps } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';

type LanguagePreferenceFormProps = BaseFormProps;

export const LanguagePreferenceForm: React.FC<LanguagePreferenceFormProps> = ({
  formId,
}) => {
  const { form, onReset, onSubmit } = useLanguagePreferenceForm();

  const { control, handleSubmit, setValue } = form;

  const { t } = useTranslation(['user']);

  const languageOptions: SelectOption<string>[] = [
    {
      label: t(
        'user:profileSettings.sections.languagePreference.fields.language.options.es',
      ),
      value: 'es',
    },
    {
      label: t(
        'user:profileSettings.sections.languagePreference.fields.language.options.en',
      ),
      value: 'en',
    },
  ];

  return (
    <form
      data-lgg-id="language-preferences-form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
    >
      <Controller
        name="language"
        control={control}
        render={({ field }) => {
          const selectedOption = languageOptions.find(
            (option) => option.value === field.value,
          );

          return (
            <Select
              name="select-language"
              value={selectedOption}
              options={languageOptions}
              label={t(
                'user:profileSettings.sections.languagePreference.fields.language.label',
              )}
              placeholder={t(
                'user:profileSettings.sections.languagePreference.fields.language.placeholder',
              )}
              isSearchable={false}
              onChange={(option) => setValue(field.name, option?.value as string)}
            />
          );
        }}
      />
    </form>
  );
};
