import React, { memo, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ContactInteractionWhatsapp,
  ContactInteractionWhatsappTemplate,
  ContactInteractionWhatsappTemplateComponentBody,
  ContactInteractionWhatsappTemplateComponentHeader,
  WhatsappTemplate,
  WhatsappTemplateComponentBody,
  WhatsappTemplateComponentFooter,
  WhatsappTemplateComponentHeader,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionWhatsappExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { TextFormatter } from 'src/components/general/text-formatter';
import {
  WhatsappTemplateParameterInput,
  WhatsappTemplateData,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/add-whatsapp-template-option';
import { WhatsappTemplateParameter } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/whatsapp-template-parameter';
import {
  BubbleDivider,
  BubbleMessageHeader,
  MessageBubbleWithDirection,
  FailedMessageIndicator,
} from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { TextItem } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';
import {
  ReactionsWrapper,
  getMessageComponentReaction,
} from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/reactions/reactions-wrapper';

// Map whatsapp formatting syntax to the one supported by markdown-it
// _italic_ -> *italic*
// *bold* -> **bold**
// ~strikethrough~ -> ~~strikethrough~~
const sanitizeWhatsappMessage = (message: string) => {
  return message
    .replace(/~(~*[^~\n]+~*)~/g, '~~$1~~')
    .replace(/\*(\**[^*\n]+\**)\*/g, '**$1**')
    .replace(/_(_*[^_\n]+_*)_/g, '*$1*');
};

const WhatsappMessageItem = (props: {
  contactInteraction: ContactInteractionWhatsapp;
  conversationId: string;
}) => {
  const { contactInteraction, conversationId } = props;

  const buildManualInteractionTitle = () => {
    const transComponents = { strong: <strong /> };

    return matchContactInteractionWhatsappExhaustive(contactInteraction, {
      outbound: () => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.whatsapp.outbound"
          components={transComponents}
        />
      ),
      inboundWithParticipatingUser: ({ participatingUser }) => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.whatsapp.inboundWithParticipatingUser"
          components={transComponents}
          values={{ participatingUserName: participatingUser.fullName }}
        />
      ),
      inboundWithoutParticipatingUser: () => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.whatsapp.inboundWithoutParticipatingUser"
          components={transComponents}
        />
      ),
    });
  };

  return (
    <TextItem
      testId="contact-interaction-whatsapp"
      contactInteraction={contactInteraction}
      conversationId={conversationId}
      titleBuilder={buildManualInteractionTitle}
      messageSanitizer={(message) => sanitizeWhatsappMessage(message)}
    />
  );
};

const TemplateHeaderText = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  display: block;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  margin-bottom: 10px;
  text-align: left;

  ${up('md')} {
    line-height: 17px;
    font-size: 14px;
  }
`;

const TemplateBodyText = styled(TemplateHeaderText)<{ marginBottom: number }>`
  font-family: ${({ theme }) => theme.font.regular};
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const TemplateFooterText = styled(TemplateHeaderText)`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 13px;
`;

const StyledBubbleMessageHeader = styled(BubbleMessageHeader)`
  .interaction-icon {
    margin-right: 6px;
    svg {
      height: 14px;
      width: 14px;
      path {
        fill: ${({ theme }) => theme.colors.gogo};
      }
    }
  }
`;

const EditWhatsappParameterContextMenuArrowStyleOverride = createGlobalStyle<{
  theme: DefaultTheme;
}>`
.ant-dropdown.edit-whatsapp-parameter-dropdown {
    .ant-dropdown-arrow {
      width: 8px;
      border: 1px solid ${({ theme }) => theme.colors.koala};
      background: white;
      border-bottom: none;
      border-right: none;
    }
  }
`;

const TemplateComponentPreviewContainer = styled.span`
  white-space: pre-wrap;
`;

const TemplatePreviewBodyText = styled(TemplateBodyText)`
  line-height: 21px;
  font-size: 13px;

  ${up('md')} {
    font-size: 14px;
  }
`;

type WhatsappTemplatePreviewItemProps = {
  occurredAt: string;
  template: WhatsappTemplate;
  parameters: WhatsappTemplateData;
  setParameters: ValueChanged<WhatsappTemplateData>;
};

export const WhatsappTemplatePreviewItem = memo<WhatsappTemplatePreviewItemProps>(
  ({ template, occurredAt, parameters, setParameters }) => {
    const hasFooterComponent = template.components.some(
      (component) => component.__typename === 'WhatsappTemplateComponentFooter',
    );
    const paramRegex = useMemo(() => /{{\d}}/g, []);
    const templateParameterInputDefaultState: WhatsappTemplateParameterInput = useMemo(
      () => ({
        hasError: false,
        value: undefined,
      }),
      [],
    );

    useEffect(() => {
      if (!parameters.rawBody) {
        const rawBody =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentBody',
          ) as WhatsappTemplateComponentBody) || undefined;
        const bodyParams = [
          ...(rawBody?.text.match(paramRegex) || [])?.map(
            () => templateParameterInputDefaultState,
          ),
        ];

        const rawHeader =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentHeader',
          ) as WhatsappTemplateComponentHeader) || undefined;

        const headerParams = [
          ...(rawHeader?.text.match(paramRegex) || [])?.map(
            () => templateParameterInputDefaultState,
          ),
        ];

        const rawFooter =
          (template.components.find(
            (template) => template.__typename === 'WhatsappTemplateComponentFooter',
          ) as WhatsappTemplateComponentFooter) || undefined;

        setParameters({
          ...parameters,
          rawBody: rawBody.text,
          rawHeader: rawHeader?.text,
          rawFooter: rawFooter?.text,
          body: bodyParams,
          header: headerParams,
          bodyParamsCount: bodyParams.length,
          headerParamsCount: headerParams.length,
        });
      }
    }, [
      paramRegex,
      parameters,
      setParameters,
      template.components,
      templateParameterInputDefaultState,
    ]);

    const resolveComponentValue = (
      component: WhatsappTemplateComponentHeader | WhatsappTemplateComponentBody,
    ) => {
      const resolvedText = component.text;

      const getComponentKey = (key: string) => {
        return match(component)
          .with(
            { __typename: 'WhatsappTemplateComponentHeader' },
            () => `header-param-${key}`,
          )
          .with(
            { __typename: 'WhatsappTemplateComponentBody' },
            () => `body-param-${key}`,
          )
          .exhaustive();
      };

      const getParamIndex = (key: string) =>
        Number(key.replace('{{', '').replace('}}', '')) - 1;

      const textComponents = resolvedText
        .split(paramRegex)
        .map((textValue, index) => (
          <span key={getComponentKey(`text-${index}`)}>{textValue}</span>
        ));

      const getParamValue = (key: string) => {
        const paramIndex = getParamIndex(key);

        return match(component)
          .with(
            { __typename: 'WhatsappTemplateComponentBody' },
            () => parameters.body[paramIndex],
          )
          .with({ __typename: 'WhatsappTemplateComponentHeader' }, () => {
            return parameters.header[paramIndex];
          })
          .exhaustive();
      };

      const setParamValue = (key: string, value: string | undefined) => {
        const paramIndex = getParamIndex(key);

        const getUpdatedParamValue = (value: string): WhatsappTemplateParameterInput => ({
          hasError: false,
          value: {
            type: 'text',
            text: value,
          },
        });

        return match(component)
          .with({ __typename: 'WhatsappTemplateComponentHeader' }, () => {
            const updatedParams = [...parameters.header];

            if (!value) {
              updatedParams[paramIndex] = templateParameterInputDefaultState;
            } else {
              updatedParams[paramIndex] = getUpdatedParamValue(value);
            }

            setParameters({
              ...parameters,
              header: updatedParams,
            });
          })
          .with({ __typename: 'WhatsappTemplateComponentBody' }, () => {
            const updatedParams = [...parameters.body];

            if (!value) {
              updatedParams[paramIndex] = templateParameterInputDefaultState;
            } else {
              updatedParams[paramIndex] = getUpdatedParamValue(value);
            }

            setParameters({
              ...parameters,
              body: updatedParams,
            });
          })
          .exhaustive();
      };

      const paramsComponents = [...resolvedText.matchAll(paramRegex)].map(
        ([paramKey]) => {
          const paramValue = getParamValue(paramKey);
          const componentKey = getComponentKey(getParamIndex(paramKey).toString());

          return (
            <WhatsappTemplateParameter
              testId={componentKey}
              key={componentKey}
              label={paramKey}
              value={paramValue?.value?.text}
              hasError={paramValue?.hasError}
              onChange={(value) => {
                setParamValue(paramKey, value);
              }}
            />
          );
        },
      );

      const templateComponents: JSX.Element[] = [];

      textComponents.forEach((component, index) => {
        templateComponents.push(component);
        templateComponents.push(paramsComponents[index]);
      });

      return (
        <TemplateComponentPreviewContainer>
          {templateComponents.map((component) => component)}
        </TemplateComponentPreviewContainer>
      );
    };

    return (
      <MessageBubbleWithDirection
        testId="contact-interaction-whatsapp-template"
        isCreationAutomatic={true}
        direction="OUTBOUND"
        deliveryStatus="DELIVERED"
        details={null}
        createdAt={occurredAt}
      >
        <>
          {template.components.map((component) =>
            match(component)
              .with({ __typename: 'WhatsappTemplateComponentHeader' }, (component) => (
                <TemplateHeaderText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-header"
                >
                  {resolveComponentValue(component)}
                </TemplateHeaderText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentBody' }, (component) => (
                <TemplatePreviewBodyText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-body"
                  marginBottom={hasFooterComponent ? 8 : 15}
                >
                  {resolveComponentValue(component)}
                </TemplatePreviewBodyText>
              ))
              .with({ __typename: 'WhatsappTemplateComponentFooter' }, (component) => (
                <TemplateFooterText
                  key={component.__typename}
                  data-lgg-id="contact-interaction-whatsapp-template-component-footer"
                >
                  <TextFormatter>{component.text}</TextFormatter>
                </TemplateFooterText>
              ))
              .exhaustive(),
          )}
          <EditWhatsappParameterContextMenuArrowStyleOverride />
        </>
      </MessageBubbleWithDirection>
    );
  },
);

const WhatsappTemplateItem = (props: {
  contactInteraction: ContactInteractionWhatsapp & {
    template: ContactInteractionWhatsappTemplate;
  };
  conversationId: string;
}) => {
  const { t } = useTranslation(['conversations']);
  const { contactInteraction, conversationId } = props;
  const {
    isCreationAutomatic,
    id,
    description,
    occurredAt,
    source,
    campaign,
    department,
    template,
  } = contactInteraction;

  const hasFooterComponent = template.components.some(
    (component) =>
      component.__typename === 'ContactInteractionWhatsappTemplateComponentFooter',
  );

  const resolveComponentValue = (
    component:
      | ContactInteractionWhatsappTemplateComponentHeader
      | ContactInteractionWhatsappTemplateComponentBody,
  ) => {
    let resolvedText = component.text;

    component.variables.forEach((variable, index) => {
      resolvedText = resolvedText.replace(`{{${index + 1}}}`, variable.text);
    });

    return resolvedText;
  };

  const messageReaction = getMessageComponentReaction(contactInteraction);

  const showFailedMessageError = template.status === 'FAILED';

  return (
    <>
      <ReactionsWrapper
        reactions={messageReaction ? [messageReaction] : messageReaction}
        direction={contactInteraction.direction}
      >
        <MessageBubbleWithDirection
          testId="contact-interaction-whatsapp-template"
          isCreationAutomatic={isCreationAutomatic}
          direction="OUTBOUND"
          deliveryStatus={template.status}
          details={{
            interactionId: id,
            conversationId,
            source,
            campaign,
            department,
            message: description,
          }}
          createdAt={occurredAt}
        >
          <>
            <StyledBubbleMessageHeader
              icon="whatsappTemplates"
              contactInteraction={contactInteraction}
              title={t('conversations:contactInteractionBubble.whatsapp.template')}
            />
            <BubbleDivider direction="OUTBOUND" />
            {template.components.map((component) =>
              match(component)
                .with(
                  { __typename: 'ContactInteractionWhatsappTemplateComponentHeader' },
                  (component) => (
                    <TemplateHeaderText
                      key={component.__typename}
                      data-lgg-id="contact-interaction-whatsapp-template-component-header"
                    >
                      <TextFormatter>{resolveComponentValue(component)}</TextFormatter>
                    </TemplateHeaderText>
                  ),
                )
                .with(
                  { __typename: 'ContactInteractionWhatsappTemplateComponentBody' },
                  (component) => (
                    <TemplateBodyText
                      key={component.__typename}
                      data-lgg-id="contact-interaction-whatsapp-template-component-body"
                      marginBottom={hasFooterComponent ? 5 : 15}
                    >
                      <TextFormatter>
                        {sanitizeWhatsappMessage(resolveComponentValue(component))}
                      </TextFormatter>
                    </TemplateBodyText>
                  ),
                )
                .with(
                  { __typename: 'ContactInteractionWhatsappTemplateComponentFooter' },
                  (component) => (
                    <TemplateFooterText
                      key={component.__typename}
                      data-lgg-id="contact-interaction-whatsapp-template-component-footer"
                    >
                      <TextFormatter>{component.text}</TextFormatter>
                    </TemplateFooterText>
                  ),
                )
                .exhaustive(),
            )}
          </>
        </MessageBubbleWithDirection>
      </ReactionsWrapper>
      {showFailedMessageError && <FailedMessageIndicator {...template} />}
    </>
  );
};

type WhatsappItemProps = {
  contactInteraction: ContactInteractionWhatsapp;
  conversationId: string;
};

export const WhatsappItem = memo<WhatsappItemProps>(
  ({ contactInteraction, conversationId }) => {
    return match(contactInteraction)
      .with(
        {
          template: P.not(P.nullish),
        },
        (contactInteraction) => (
          <WhatsappTemplateItem
            contactInteraction={contactInteraction}
            conversationId={conversationId}
          />
        ),
      )
      .otherwise(() => (
        <WhatsappMessageItem
          contactInteraction={contactInteraction}
          conversationId={conversationId}
        />
      ));
  },
);
