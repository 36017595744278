import { gql } from '@apollo/client';
import {
  CONTACT_BLOCKING_FIELDS,
  CONTACT_INTERACTION_COMPONENT_STATUS,
  CORE_ATTACHMENTS_FIELDS_WITH_REACTIONS_AND_RELATED,
  CORE_CHAT_MESSAGES_FIELDS_WITH_REACTIONS_AND_RELATED,
  CORE_REACTION_FIELDS,
} from 'src/components/providers/apollo-provider-provider';

export const CONVERSATION_FOR_CONTACT_INTERACTION_LIST = gql`
  ${CORE_REACTION_FIELDS}
  ${CORE_ATTACHMENTS_FIELDS_WITH_REACTIONS_AND_RELATED}
  ${CORE_CHAT_MESSAGES_FIELDS_WITH_REACTIONS_AND_RELATED}
  ${CONTACT_INTERACTION_COMPONENT_STATUS}
  ${CONTACT_BLOCKING_FIELDS}
  query GetConversationForContactInteractionList(
    $id: String!
    $last: Int
    $after: String
    $first: Int
    $before: String
    $where: ConversationItemsWhereInput
  ) {
    conversation(id: $id) {
      id
      isMarked
      isOpen
      isReplyPending
      isSnoozed
      isStarted
      snoozedUntil
      updatedAt
      contact {
        id
        label
        primaryEmail
        ...ContactBlockingFieldsFragment
        status {
          id
          name
        }
        tags {
          id
          name
        }
        stage {
          id
          slug
        }
        assignee {
          id
        }
      }
      lastContactInteraction {
        ... on ContactInteraction {
          id
          occurredAt
        }
        ... on ContactInteractionPhoneCall {
          callDetail {
            isInProgress
          }
        }
      }
      items(last: $last, before: $before, after: $after, first: $first, where: $where) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            ... on ContactInteraction {
              id
              occurredAt
              createdAt
              description
              resourceAddress
              contactAddress
              participatingUser {
                id
                fullName
              }
              isCreationAutomatic
              source {
                id
                name
              }
              campaign {
                id
                name
              }
              department {
                id
                name
              }
            }
            ... on ContactInteractionWithDirection {
              direction
            }
            ... on ContactInteractionWithAttachments {
              ...ContactInteractionAttachmentsFragmentWithReactionsAndRelated
            }
            ... on ContactInteractionChatMessage {
              ...ContactInteractionChatMessagesFragmentWithReactionsAndRelated
            }
            ... on ContactInteractionWhatsapp {
              template {
                id
                ...ContactInteractionComponentStatus
                name
                language
                ...ContactInteractionComponentReactionsFragment
                components {
                  ... on ContactInteractionWhatsappTemplateComponent {
                    text
                    format
                  }
                  ... on ContactInteractionWhatsappTemplateComponentBody {
                    variables {
                      text
                    }
                  }
                  ... on ContactInteractionWhatsappTemplateComponentHeader {
                    variables {
                      text
                    }
                  }
                }
              }
            }
            ... on ContactInteractionFacebookAd {
              fields {
                key
                label
                value
              }
            }
            ... on ContactInteractionEmail {
              subject
              bodyText
              bodyHtml
            }
            ... on ContactInteractionPhoneCall {
              callDetail {
                duration
                waitTime
                talkTime
                inboundLastStep
                outboundDialUnansweredReason
                isInProgress
                brief {
                  ... on ContactInteractionBrief {
                    text
                  }
                  ... on AsyncStatusPending {
                    asyncStatus
                  }
                  ... on ContactInteractionBriefDisabled {
                    asyncStatus
                  }
                }
                summary {
                  ... on ContactInteractionSummary {
                    text
                  }
                  ... on AsyncStatusPending {
                    asyncStatus
                  }
                  ... on ContactInteractionSummaryDisabled {
                    asyncStatus
                  }
                }
                sentimentAnalysis {
                  ... on ContactInteractionSentimentAnalysis {
                    id
                    phrases {
                      id
                      emotions
                      end
                      phrase
                      sentiment
                      speakerType
                      start
                    }
                    totalNegatives
                    totalPositives
                  }
                  ... on AsyncStatusPending {
                    asyncStatus
                  }
                  ... on SentimentAnalysisDisabled {
                    asyncStatus
                  }
                }
                transcription {
                  ... on ContactInteractionTranscription {
                    id
                    phrases {
                      edges {
                        cursor
                        node {
                          id
                          emotions
                          end
                          phrase
                          sentiment
                          speakerType
                          start
                        }
                      }
                    }
                    contact {
                      id
                      label
                      status {
                        id
                        name
                      }
                      stage {
                        id
                        slug
                      }
                    }
                    user {
                      id
                      fullName
                      avatar {
                        color
                        initials
                      }
                    }
                  }
                  ... on AsyncStatusPending {
                    asyncStatus
                  }
                  ... on ContactInteractionTranscriptionDisabled {
                    asyncStatus
                  }
                }
              }
              dialStatus
              answeredPhone {
                national
              }
              recording {
                url
              }
            }
            ... on ConversationNote {
              id
              note
              rawNote
              createdAt
              createdBy {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export const CONVERSATION_WITH_LAST_INTERACTION = gql`
  query GetConversation($id: String!) {
    conversation(id: $id) {
      id
      lastContactInteraction {
        ... on ContactInteraction {
          id
          occurredAt
        }
        ... on ContactInteractionWithDirection {
          direction
        }
        ... on ContactInteractionPhoneCall {
          callDetail {
            isInProgress
          }
        }
      }
    }
  }
`;

export const CONVERSATION_UNSNOOZE = gql`
  mutation ConversationUnsnooze($conversationId: String!) {
    conversationUnsnooze(conversationId: $conversationId) {
      conversation {
        id
        isSnoozed
        snoozedUntil
        snoozedBy {
          id
        }
      }
    }
  }
`;
