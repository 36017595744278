import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import { ContactInteractionSms } from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionSmsExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { TextItem } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';

type SmsItemProps = {
  contactInteraction: ContactInteractionSms;
  conversationId: string;
};

export const SmsItem = memo<SmsItemProps>(({ contactInteraction, conversationId }) => {
  const buildManualInteractionTitle = () => {
    const transComponents = { strong: <strong /> };

    return matchContactInteractionSmsExhaustive(contactInteraction, {
      outbound: () => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.sms.outbound"
          components={transComponents}
        />
      ),
      inboundWithParticipatingUser: ({ participatingUser }) => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.sms.inboundWithParticipatingUser"
          components={transComponents}
          values={{ participatingUserName: participatingUser.fullName }}
        />
      ),
      inboundWithoutParticipatingUser: () => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.sms.inboundWithoutParticipatingUser"
          components={transComponents}
        />
      ),
    });
  };

  return (
    <TextItem
      testId="contact-interaction-sms"
      contactInteraction={contactInteraction}
      conversationId={conversationId}
      titleBuilder={buildManualInteractionTitle}
    />
  );
});
