import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ContactInteractionAttachmentFile,
  ContactInteractionUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchConversationAttachmentExhaustive } from '@lgg/isomorphic/utils/match-conversation-attachment';
import { Icon } from 'src/components/general/icon';
import { TextFormatter } from 'src/components/general/text-formatter';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { formatAudioDuration } from 'src/components/pages/conversations/components/contact-interactions/items/helpers';
import { ContactInteractionsListContext } from 'src/components/pages/conversations/components/contact-interactions/shared';
import { useUrls } from 'src/hooks/use-urls';

const PreviewContainer = styled(FlexColumn)`
  background-color: #e8ebed;
  border-left: 4px solid ${({ theme }) => theme.colors.gogo};
  border-radius: 6px;
  margin: -10px -10px 10px;
  padding: 10px 10px 10px 6px;
  position: relative;

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.porcelain};
  }
`;

const SubjectLabel = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin-bottom: 4px;
  text-align: left;
`;

const TextMessagePreview = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: left;
`;

const RepliedPreviewContainer = styled(FlexRow)`
  align-items: center;
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: left;
`;

const PreviewIcon = styled(Icon)`
  svg {
    height: 12px;
    margin-right: 5px;
    width: 12px;
  }
`;

const RepliedAudioAttachmentPreview = (props: {
  attachment: ContactInteractionAttachmentFile;
}) => {
  const { attachment } = props;
  const [duration, setDuration] = useState('00:00');

  useEffect(() => {
    const audio = new Audio(attachment.url);
    const onLoadCallback = () => {
      setDuration(formatAudioDuration(audio.duration));
    };

    audio.addEventListener('loadedmetadata', onLoadCallback);

    return () => {
      audio.removeEventListener('loadedmetadata', onLoadCallback);
    };
  }, [attachment.url]);

  return (
    <RepliedPreviewContainer>
      <PreviewIcon data-lgg-id="preview-icon" type="attachedAudio" />
      {duration}
    </RepliedPreviewContainer>
  );
};

const RepliedAttachmentPreview = (props: { icon: string; text?: string | null }) => {
  const { icon, text } = props;

  return (
    <RepliedPreviewContainer>
      <PreviewIcon data-lgg-id="preview-icon" type={icon} />
      {text}
    </RepliedPreviewContainer>
  );
};

type RepliedItemPreviewProps = {
  contactInteraction: ContactInteractionUnion;
};

export const RepliedItemPreview = memo<RepliedItemPreviewProps>(
  ({ contactInteraction, ...rest }) => {
    const { t } = useTranslation(['conversations']);
    const { getConversationContactInteractionUrl } = useUrls();
    const history = useHistory();
    const {
      contactInteractions: loadedContactInteractions,
      virtuosoHandle,
      virtuosoScroller,
      conversationId,
    } = useContext(ContactInteractionsListContext);
    const subject = match(contactInteraction)
      .with(
        { direction: 'OUTBOUND', participatingUser: P.not(P.nullish) },
        ({ participatingUser }) => participatingUser.fullName,
      )
      .otherwise(() => contactInteraction.contact.label);

    const content = match(contactInteraction)
      .with(
        { attachments: P.when((attachments) => attachments.length) },
        (contactInteraction) => {
          const firstAttachment = contactInteraction.attachments?.[0];

          return matchConversationAttachmentExhaustive(firstAttachment, {
            audio: (attachment) => (
              <RepliedAudioAttachmentPreview attachment={attachment} />
            ),
            image: () => (
              <RepliedAttachmentPreview
                icon="attachedImage"
                text={t('conversations:repliedItemPreview.image')}
              />
            ),
            sticker: () => (
              <RepliedAttachmentPreview
                icon="attachedImage"
                text={t('conversations:repliedItemPreview.image')}
              />
            ),
            video: () => (
              <RepliedAttachmentPreview
                icon="attachedVideo"
                text={t('conversations:repliedItemPreview.video')}
              />
            ),
            document: ({ filename, originalFilename }) => (
              <RepliedAttachmentPreview
                icon="attach"
                text={originalFilename ?? filename}
              />
            ),
            location: () => (
              <RepliedAttachmentPreview
                icon="location"
                text={t('conversations:repliedItemPreview.location')}
              />
            ),
            contacts: (attachment) => {
              const { firstName, lastName } = attachment.contacts[0].name;

              return (
                <RepliedAttachmentPreview
                  icon="contact"
                  text={[firstName, lastName].filter(Boolean).join(' ')}
                />
              );
            },
          });
        },
      )
      .with(
        {
          message: P.not(P.nullish),
        },
        ({ message }) => {
          const regex = /^.*\n.{0,5}/;
          const normalizedText = message.content.match(regex);

          return (
            <TextFormatter>
              {normalizedText ? normalizedText[0] + '...' : message.content ?? ''}
            </TextFormatter>
          );
        },
      )
      .otherwise(() => {
        return null;
      });

    const handleRepliedContactInteractionRedirect = () => {
      // Get the replied contact interaction id
      const repliedInteractionId = contactInteraction.id;

      // Try to find the index of the replied contact interaction in the loaded contact interactions list
      const repliedContactInteractionIndex = loadedContactInteractions.findIndex(
        (ci) => ci.id === repliedInteractionId,
      );

      if (repliedContactInteractionIndex !== -1 && virtuosoHandle && virtuosoScroller) {
        // As per WhatsApp contact interactions, we have an attachment or a message, if we have an attachment we pick its id,
        // otherwise we pick the id of the contact interaction
        const repliedItemId = match(contactInteraction)
          .with(
            { attachments: P.when((attachments) => attachments.length > 0) },
            ({ attachments }) => attachments[0].id,
          )
          .otherwise(() => repliedInteractionId);

        // As we have the index of the replied contact interaction, we can scroll to it
        virtuosoHandle.scrollToIndex({
          index: repliedContactInteractionIndex,
          behavior: 'smooth',
          offset: -20,
        });

        setTimeout(() => {
          // Contact interaction items have a data-id attribute with the id of the contact interaction
          // but attachment items have a data-id attribute with the literal `attachment` plus attachmentId
          // So we can differentiate between CI and attachments
          const selectorId = `${
            repliedInteractionId !== repliedItemId ? 'attachment-' : ''
          }${repliedItemId}`;
          const selector = `[data-id="${selectorId}"]`;
          const itemToHighlight = (virtuosoScroller as HTMLDivElement).querySelector(
            selector,
          ) as HTMLDivElement | null;

          if (!itemToHighlight) {
            return;
          }
          // Add class to container to highlight the replied contact interaction
          itemToHighlight.classList.add('ci-highlighted');

          // Animation class is removed after animation ends
          itemToHighlight.addEventListener('animationend', () => {
            itemToHighlight.classList.remove('ci-highlighted');
          });
        }, 1000);
      } else {
        // If we don't have the replied contact interaction in the loaded contact interactions list,
        // we redirect to the conversation contact interaction page using the direct link
        // to the replied contact interaction
        history.push(
          getConversationContactInteractionUrl({
            conversationId,
            contactInteractionId: repliedInteractionId,
          }),
        );
      }
    };

    return (
      <PreviewContainer {...rest} onClick={handleRepliedContactInteractionRedirect}>
        <SubjectLabel>{subject}</SubjectLabel>
        <TextMessagePreview data-lgg-id="preview-container">{content}</TextMessagePreview>
      </PreviewContainer>
    );
  },
);
