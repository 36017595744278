import React, { memo } from 'react';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const StyledStatusTag = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 4px;
  height: 24px;
  margin-right: 10px;
  padding: 0 8px;
`;

const StatusTagIcon = styled(Icon)`
  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const StatusTagLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  margin-left: 10px;
  text-align: left;
`;

type StatusTagProps = {
  icon: string;
  label: string;
  onClick?: VoidFunction;
};

export const StatusTag = memo<StatusTagProps>(({ icon, label, ...rest }) => {
  return (
    <StyledStatusTag {...rest}>
      <StatusTagIcon type={icon} />
      <StatusTagLabel>{label}</StatusTagLabel>
    </StyledStatusTag>
  );
});
