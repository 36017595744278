import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import 'antd/es/calendar/style';
import { Dropdown } from 'antd';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import * as zod from 'zod';
import { z } from 'zod';
import {
  InputDateType,
  InputTimeUnit,
  matchAdvancedDateInputTypeExhaustive,
  matchInputDateTypeExhaustive,
  matchInputDateUnitExhaustive,
} from '@lgg/isomorphic/utils/match-advanced-date-input';
import { DateFilter } from 'src/components/filters/react-filters';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import {
  CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT,
  CustomDatePickerInputPlaceholder,
  CustomDatePickerPopoverFooter,
  CustomDatePickerSelect,
  CustomPickerDatePreview,
  InputContainer,
} from 'src/components/general/inputs/custom-date-pickers/shared';
import { GenericInput } from 'src/components/general/inputs/generic-input';
import { SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';

const StyledForm = styled.form`
  width: 100%;

  ${up('md')} {
    width: 389px;
  }
`;

const BodyContainer = styled(FlexColumn)`
  min-height: 165px;
  padding: 20px;
  width: 100%;

  ${up('md')} {
    min-height: unset;
    padding: 15px 10px;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 38px;

  ${up('md')} {
    height: 34px;
    max-width: 56px;
  }
`;

const FormContent = styled(FlexColumn)`
  position: relative;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const CustomDatePickerDateFilter = styled(DateFilter)`
  .ant-picker {
    height: 38px;
    padding: 10px 8px 9px 10px;

    ${up('md')} {
      height: 34px;
      max-width: 120px;
    }
  }

  .ant-picker-suffix {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

type AdvancedDateFilterTypeWithUnit =
  | '_isLastXDays'
  | '_isLastXWeeks'
  | '_isLastXMonths'
  | '_isLastXYears'
  | '_isOverXDays'
  | '_isOverXWeeks'
  | '_isOverXMonths'
  | '_isOverXYears';

type AdvancedDateFilterBooleanType =
  | '_isToday'
  | '_isThisWeek'
  | '_isThisMonth'
  | '_isThisYear'
  | '_isYesterday'
  | '_isLastWeek'
  | '_isLastMonth'
  | '_isLastYear'
  | '_notSet';

export type AdvancedDateFilterType =
  | '_isBetween'
  | '_isExactDate'
  | AdvancedDateFilterBooleanType
  | AdvancedDateFilterTypeWithUnit;

const advancedDateFilterResolver: Record<AdvancedDateFilterType, InputDateType> = {
  _isToday: 'TODAY',
  _isThisWeek: 'THIS_WEEK',
  _isThisMonth: 'THIS_MONTH',
  _isThisYear: 'THIS_YEAR',
  _isYesterday: 'YESTERDAY',
  _isLastWeek: 'LAST_WEEK',
  _isLastMonth: 'LAST_MONTH',
  _isLastYear: 'LAST_YEAR',
  _notSet: 'NO_DATE',
  _isLastXDays: 'IN_THE_LAST',
  _isLastXWeeks: 'IN_THE_LAST',
  _isLastXMonths: 'IN_THE_LAST',
  _isLastXYears: 'IN_THE_LAST',
  _isOverXDays: 'OVER',
  _isOverXWeeks: 'OVER',
  _isOverXMonths: 'OVER',
  _isOverXYears: 'OVER',
  _isBetween: 'DATE_RANGE',
  _isExactDate: 'EXACT_DATE',
};

type AdvancedDatePickerValues = {
  type: AdvancedDateFilterType | null;
  unit: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
};

type Props = {
  onChange: (
    props:
      | {
          type: '_isExactDate';
          dateFrom: Date | null;
        }
      | {
          type: '_isBetween';
          dateFrom: Date | null;
          dateTo: Date | null;
        }
      | {
          type: AdvancedDateFilterTypeWithUnit;
          unit: number;
        }
      | {
          type: AdvancedDateFilterBooleanType;
        }
      | {
          type: null;
        },
  ) => void;
  values: AdvancedDatePickerValues;
  label: string;
  resetInnerForm?: boolean;
  'data-lgg-id'?: string;
};

export const AdvancedDatePicker = ({
  onChange,
  values,
  label,
  resetInnerForm = false,
  'data-lgg-id': testId,
}: Props) => {
  const { type, dateTo, dateFrom, unit } = values;
  const { visible: isPanelVisible, close: closePanel, show: showPanel } = useVisible();
  const { t } = useTranslation(['common']);
  const [fieldCurrentValue, setFieldCurrentValue] =
    useState<AdvancedDateFilterType | null>(type ?? null);
  const breakpointUpMd = useBreakpoint(up('md'));
  const { formatDate } = useFormatDate();
  const {
    subDays,
    subMonths,
    subWeeks,
    subYears,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    endOfMonth,
    endOfWeek,
    endOfYear,
    getNowDateAsInTimezone,
  } = useDateHelpers();
  const [pickerType, setPickerType] = useState<InputDateType | null>(
    type ? advancedDateFilterResolver[type] : null,
  );

  const getDefaultUnitValue = useCallback(() => {
    return match(type)
      .with(P.union('_isLastXDays', '_isOverXDays'), () => 'DAY' as InputTimeUnit)
      .with(P.union('_isLastXWeeks', '_isOverXWeeks'), () => 'WEEK' as InputTimeUnit)
      .with(P.union('_isLastXMonths', '_isOverXMonths'), () => 'MONTH' as InputTimeUnit)
      .with(P.union('_isLastXYears', '_isOverXYears'), () => 'YEAR' as InputTimeUnit)
      .otherwise(() => null);
  }, [type]);

  const [pickerTimeUnitValue, setPickerTimeUnitValue] = useState<number>(unit ?? 1);
  const [pickerTimeUnit, setPickerTimeUnit] = useState<InputTimeUnit>(
    getDefaultUnitValue() ?? 'DAY',
  );
  const [pickerStartDate, setPicketStartDate] = useState<Date | null>(dateFrom ?? null);
  const [pickerEndDate, setPickerEndDate] = useState<Date | null>(dateTo ?? null);

  const formatInputDate = useCallback(
    (date: Date) => formatDate(date, CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT, false),
    [formatDate],
  );

  const schema = useMemo(() => {
    return zod
      .object({
        timeUnitValue: zod.number().positive(),
        timeUnit: z.union([
          z.literal('DAY'),
          z.literal('WEEK'),
          z.literal('MONTH'),
          z.literal('YEAR'),
        ]),
        startDate: zod.string().nullish(),
        endDate: zod.string().nullish(),
      })
      .required();
  }, []);

  type FormValues = zod.infer<typeof schema>;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = useMemo(() => getNowDateAsInTimezone(), []);

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      timeUnitValue: 1,
      timeUnit: 'DAY',
      startDate: null,
      endDate: null,
    },
    values: {
      timeUnitValue: unit ?? 1,
      timeUnit: getDefaultUnitValue() ?? 'DAY',
      startDate: dateFrom ? dateFrom.toISOString() : null,
      endDate: dateTo ? dateTo.toISOString() : null,
    },
    shouldFocusError: true,
  });

  useEffect(() => {
    if (resetInnerForm) {
      form.reset();
      setFieldCurrentValue(type ? type : null);
      setPickerType(type ? advancedDateFilterResolver[type] : null);
    }
  }, [form, resetInnerForm, type]);

  const updateFormDates = useCallback(
    ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
      if (startDate) {
        setPicketStartDate(startDate);
      }

      if (endDate) {
        setPickerEndDate(endDate);
      }
    },
    [],
  );

  const getParsedFormValues = useCallback(() => {
    const { startDate, endDate, timeUnit, timeUnitValue } = form.getValues();

    return {
      startDate: startDate ? parseISO(startDate) : null,
      endDate: endDate ? parseISO(endDate) : null,
      timeUnit,
      timeUnitValue,
    };
  }, [form, parseISO]);

  const computeDateValues = useCallback(
    (values: FormValues, previewInputType?: InputDateType | null) => {
      const inputType = previewInputType ?? pickerType;

      if (!inputType) return;
      matchInputDateTypeExhaustive(inputType, {
        dateRange: () => {
          const { startDate, endDate } = values;

          if (startDate && endDate) {
            updateFormDates({
              startDate: parseISO(startDate),
              endDate: parseISO(endDate),
            });
          }
        },
        exactDate: () => {
          const { startDate } = values;

          if (startDate) {
            updateFormDates({
              startDate: parseISO(startDate),
            });
          }
        },
        inTheLast: () => {
          if (!values.timeUnit || !values.timeUnitValue) return;

          match(values.timeUnit)
            .with('DAY', () => {
              updateFormDates({
                startDate: subDays(currentDate, (values.timeUnitValue ?? 1) - 1),
                endDate: currentDate,
              });
            })
            .with('WEEK', () => {
              const refDate = subWeeks(currentDate, values.timeUnitValue ?? 1);

              updateFormDates({
                startDate: startOfWeek(refDate),
                endDate: startOfDay(endOfWeek(currentDate)),
              });
            })
            .with('MONTH', () => {
              updateFormDates({
                startDate: startOfMonth(
                  subMonths(currentDate, values.timeUnitValue ?? 1),
                ),
                endDate: startOfDay(endOfMonth(subMonths(currentDate, 1))),
              });
            })
            .with('YEAR', () => {
              updateFormDates({
                startDate: startOfYear(subYears(currentDate, values.timeUnitValue ?? 1)),
                endDate: startOfDay(endOfYear(subYears(currentDate, 1))),
              });
            })
            .exhaustive();
        },
        over: () => {
          if (!values.timeUnit || !values.timeUnitValue) return undefined;

          match(values.timeUnit)
            .with('DAY', () => {
              const refDate = currentDate;

              updateFormDates({
                startDate: subDays(refDate, values.timeUnitValue ?? 1),
                endDate: refDate,
              });
            })
            .with('WEEK', () => {
              const refDate = currentDate;

              updateFormDates({
                startDate: subWeeks(refDate, values.timeUnitValue ?? 1),
                endDate: refDate,
              });
            })
            .with('MONTH', () => {
              const refDate = currentDate;

              updateFormDates({
                startDate: subMonths(refDate, values.timeUnitValue ?? 1),
                endDate: refDate,
              });
            })
            .with('YEAR', () => {
              const refDate = currentDate;

              updateFormDates({
                startDate: subYears(refDate, values.timeUnitValue ?? 1),
                endDate: refDate,
              });
            })
            .exhaustive();
        },
        noDate: () => {},
        lastWeek: () => {
          const refDate = startOfWeek(subWeeks(currentDate, 1));

          updateFormDates({
            startDate: refDate,
            endDate: startOfDay(endOfWeek(refDate)),
          });
        },
        lastMonth: () => {
          const refDate = startOfMonth(subMonths(currentDate, 1));

          updateFormDates({
            startDate: refDate,
            endDate: startOfDay(endOfMonth(refDate)),
          });
        },
        lastYear: () => {
          const refDate = startOfYear(subYears(currentDate, 1));

          updateFormDates({
            startDate: refDate,
            endDate: startOfDay(endOfYear(refDate)),
          });
        },
        today: () => {
          const refDate = startOfDay(currentDate);

          updateFormDates({
            startDate: refDate,
          });
        },
        yesterday: () => {
          const refDate = subDays(currentDate, 1);

          updateFormDates({
            startDate: refDate,
          });
        },
        thisWeek: () => {
          const refDate = startOfWeek(currentDate);

          updateFormDates({
            startDate: refDate,
            endDate: currentDate,
          });
        },
        thisMonth: () => {
          const refDate = startOfMonth(currentDate);

          updateFormDates({
            startDate: refDate,
            endDate: currentDate,
          });
        },
        thisYear: () => {
          const refDate = startOfYear(currentDate);

          updateFormDates({
            startDate: refDate,
            endDate: currentDate,
          });
        },
      });
    },
    [
      currentDate,
      endOfMonth,
      endOfWeek,
      endOfYear,
      parseISO,
      pickerType,
      startOfDay,
      startOfMonth,
      startOfWeek,
      startOfYear,
      subDays,
      subMonths,
      subWeeks,
      subYears,
      updateFormDates,
    ],
  );

  useEffect(() => {
    const innerFormSubscription = form.watch((values) => {
      computeDateValues(values as FormValues);
    });

    return () => innerFormSubscription.unsubscribe();
  }, [computeDateValues, form]);

  useEffect(() => {
    computeDateValues(form.getValues() as FormValues);
  }, [computeDateValues, form, pickerType]);

  const resultPreview = useMemo(() => {
    if (!pickerType) return null;

    const getRangePreview = () =>
      pickerStartDate && pickerEndDate
        ? `${formatInputDate(pickerStartDate)} - ${formatInputDate(pickerEndDate)}`
        : null;

    const previewText = matchInputDateTypeExhaustive(pickerType, {
      inTheLast: () => getRangePreview(),
      lastWeek: () => getRangePreview(),
      lastMonth: () => getRangePreview(),
      lastYear: () => getRangePreview(),
      today: () => null,
      yesterday: () => (pickerStartDate ? formatInputDate(pickerStartDate) : null),
      thisWeek: () => getRangePreview(),
      thisMonth: () => getRangePreview(),
      thisYear: () => getRangePreview(),
      dateRange: () => null,
      over: () =>
        t('common:advancedDateFilter.preview.over', {
          value: pickerStartDate ? formatInputDate(pickerStartDate) : null,
        }),
      noDate: () => null,
      exactDate: () => null,
    });

    return previewText ? (
      <CustomPickerDatePreview
        data-lgg-id="advanced-date-picker-preview"
        previewText={previewText}
      />
    ) : null;
  }, [pickerEndDate, formatInputDate, pickerType, pickerStartDate, t]);

  const inputValue = useMemo(() => {
    if (!fieldCurrentValue) {
      return (
        <CustomDatePickerInputPlaceholder>
          {t('common:advancedDateFilter.placeholder')}
        </CustomDatePickerInputPlaceholder>
      );
    }

    const { timeUnit, timeUnitValue } = getParsedFormValues();

    return matchInputDateTypeExhaustive<React.ReactElement>(
      advancedDateFilterResolver[fieldCurrentValue],
      {
        dateRange: () =>
          pickerStartDate && pickerEndDate ? (
            <Trans
              i18nKey="common:advancedDateFilter.values.dateRange"
              values={{
                startDate: formatInputDate(pickerStartDate),
                endDate: formatInputDate(pickerEndDate),
              }}
            />
          ) : (
            <></>
          ),
        inTheLast: () => {
          const unitTranslation = matchInputDateUnitExhaustive(timeUnit, {
            day: () => 'common:timeUnits.day' as const,
            week: () => 'common:timeUnits.week' as const,
            month: () => 'common:timeUnits.month' as const,
            year: () => 'common:timeUnits.year' as const,
          });

          return (
            <Trans
              i18nKey="common:advancedDateFilter.values.inTheLast"
              values={{
                value: t(unitTranslation, { count: timeUnitValue }),
              }}
            />
          );
        },
        over: () => {
          const unitTranslation = matchInputDateUnitExhaustive(timeUnit, {
            day: () => 'common:agoTimeUnits.day' as const,
            week: () => 'common:agoTimeUnits.week' as const,
            month: () => 'common:agoTimeUnits.month' as const,
            year: () => 'common:agoTimeUnits.year' as const,
          });

          return (
            <Trans
              i18nKey="common:advancedDateFilter.values.over"
              values={{ value: t(unitTranslation, { count: timeUnitValue }) }}
            />
          );
        },
        noDate: () => <Trans i18nKey="common:advancedDateFilter.values.noDate" />,
        lastWeek: () => <Trans i18nKey="common:advancedDateFilter.values.lastWeek" />,
        lastMonth: () => <Trans i18nKey="common:advancedDateFilter.values.lastMonth" />,
        lastYear: () => <Trans i18nKey="common:advancedDateFilter.values.lastYear" />,
        today: () => <Trans i18nKey="common:advancedDateFilter.values.today" />,
        yesterday: () => <Trans i18nKey="common:advancedDateFilter.values.yesterday" />,
        thisWeek: () => <Trans i18nKey="common:advancedDateFilter.values.thisWeek" />,
        thisMonth: () => <Trans i18nKey="common:advancedDateFilter.values.thisMonth" />,
        thisYear: () => <Trans i18nKey="common:advancedDateFilter.values.thisYear" />,
        exactDate: () => {
          return pickerStartDate ? (
            <Trans
              i18nKey="common:advancedDateFilter.values.exactDate"
              values={{
                value: formatInputDate(pickerStartDate),
              }}
            />
          ) : (
            <></>
          );
        },
      },
    );
  }, [
    fieldCurrentValue,
    getParsedFormValues,
    t,
    pickerStartDate,
    pickerEndDate,
    formatInputDate,
  ]);

  const getCurrentFormValue = useCallback(() => {
    if (!pickerType) return null;

    return matchInputDateTypeExhaustive<AdvancedDateFilterType>(pickerType, {
      dateRange: () => '_isBetween',
      inTheLast: () => {
        return matchInputDateUnitExhaustive<AdvancedDateFilterType>(pickerTimeUnit, {
          day: () => '_isLastXDays',
          week: () => '_isLastXWeeks',
          month: () => '_isLastXMonths',
          year: () => '_isLastXYears',
        });
      },
      over: () => {
        return matchInputDateUnitExhaustive<AdvancedDateFilterType>(pickerTimeUnit, {
          day: () => '_isOverXDays',
          week: () => '_isOverXWeeks',
          month: () => '_isOverXMonths',
          year: () => '_isOverXYears',
        });
      },
      noDate: () => '_notSet',
      lastWeek: () => '_isLastWeek',
      lastMonth: () => '_isLastMonth',
      lastYear: () => '_isLastYear',
      today: () => '_isToday',
      yesterday: () => '_isYesterday',
      thisWeek: () => '_isThisWeek',
      thisMonth: () => '_isThisMonth',
      thisYear: () => '_isThisYear',
      exactDate: () => '_isExactDate',
    });
  }, [pickerTimeUnit, pickerType]);

  const onSubmit = useCallback(() => {
    const { endDate, startDate, timeUnitValue } = getParsedFormValues();
    let hasErrors = false;

    const currentFormValue = getCurrentFormValue();

    matchAdvancedDateInputTypeExhaustive(getCurrentFormValue(), {
      exact: () => {
        if (!startDate) {
          hasErrors = true;
          form.setError('startDate', { type: 'required' });
        }

        onChange({
          type: '_isExactDate',
          dateFrom: startDate,
        });
      },
      range: () => {
        if (startDate && endDate) {
          if (startDate.getTime() > endDate.getTime()) {
            hasErrors = true;
            form.setError('endDate', { type: 'validate' });
          }
        } else {
          if (!startDate) {
            hasErrors = true;
            form.setError('startDate', { type: 'required' });
          }

          if (!endDate) {
            hasErrors = true;
            form.setError('endDate', { type: 'required' });
          }
        }

        onChange({
          type: '_isBetween',
          dateFrom: startDate,
          dateTo: endDate,
        });
      },
      relativeWithUnit: (type) => {
        onChange({
          type,
          unit: timeUnitValue,
        });
      },
      relative: (type) => {
        onChange({ type });
      },
      empty: () => {
        onChange({ type: null });
      },
    });

    if (!hasErrors) {
      setFieldCurrentValue(currentFormValue);
      closePanel();
      form.clearErrors();
    }
  }, [getParsedFormValues, getCurrentFormValue, onChange, form, closePanel]);

  const options: {
    label: string;
    value: InputDateType;
  }[] = useMemo(
    () => [
      { label: t('common:advancedDateFilter.options.inTheLast'), value: 'IN_THE_LAST' },
      { label: t('common:advancedDateFilter.options.over'), value: 'OVER' },
      { label: t('common:advancedDateFilter.options.noDate'), value: 'NO_DATE' },
      { label: t('common:advancedDateFilter.options.lastWeek'), value: 'LAST_WEEK' },
      { label: t('common:advancedDateFilter.options.lastMonth'), value: 'LAST_MONTH' },
      { label: t('common:advancedDateFilter.options.lastYear'), value: 'LAST_YEAR' },
      { label: t('common:advancedDateFilter.options.today'), value: 'TODAY' },
      { label: t('common:advancedDateFilter.options.yesterday'), value: 'YESTERDAY' },
      { label: t('common:advancedDateFilter.options.thisWeek'), value: 'THIS_WEEK' },
      { label: t('common:advancedDateFilter.options.thisMonth'), value: 'THIS_MONTH' },
      { label: t('common:advancedDateFilter.options.thisYear'), value: 'THIS_YEAR' },
      { label: t('common:advancedDateFilter.options.exactDate'), value: 'EXACT_DATE' },
      { label: t('common:advancedDateFilter.options.dateRange'), value: 'DATE_RANGE' },
    ],
    [t],
  );

  const timeUnitOptions: SelectOption<InputTimeUnit>[] = useMemo(() => {
    const isOverSelected = pickerType === 'OVER';
    const translationPayload = { count: pickerTimeUnitValue };

    return [
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.day', translationPayload)
          : t('common:advancedDateFilter.unitOptions.day', translationPayload),
        value: 'DAY' as InputTimeUnit,
      },
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.week', translationPayload)
          : t('common:advancedDateFilter.unitOptions.week', translationPayload),
        value: 'WEEK' as InputTimeUnit,
      },
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.month', translationPayload)
          : t('common:advancedDateFilter.unitOptions.month', translationPayload),
        value: 'MONTH' as InputTimeUnit,
      },
      {
        label: isOverSelected
          ? t('common:advancedDateFilter.agoUnitOptions.year', translationPayload)
          : t('common:advancedDateFilter.unitOptions.year', translationPayload),
        value: 'YEAR' as InputTimeUnit,
      },
    ];
  }, [pickerType, t, pickerTimeUnitValue]);

  const timeUnitFormController = useMemo(
    () => (
      <Controller
        control={form.control}
        name="timeUnit"
        rules={{
          required: true,
        }}
        render={({ field }) => {
          const selectedOption =
            timeUnitOptions.find(
              ({ value: optionValue }) => optionValue === field.value,
            ) ?? null;

          return (
            <CustomDatePickerSelect
              options={timeUnitOptions}
              isSearchable={false}
              name="advanced-date-time-unit-select"
              isClearable={false}
              isMulti={false}
              label=""
              forceCaret
              onChange={(option) => {
                if (option?.value) {
                  form.setValue('timeUnit', option.value);
                  setPickerTimeUnit(option.value);
                }
              }}
              value={selectedOption}
            />
          );
        }}
      />
    ),
    [form, timeUnitOptions],
  );

  const timeUnitValueFormController = useMemo(
    () => (
      <Controller
        control={form.control}
        name="timeUnitValue"
        rules={{
          required: true,
          min: 1,
        }}
        render={({ field, fieldState }) => {
          return (
            <StyledTextInput
              {...field}
              data-lgg-id="advanced-date-time-unit-value-input"
              type="number"
              min={1}
              error={Boolean(fieldState.error)}
              label=""
              reserveErrorArea={false}
              onChange={(e) => {
                const currentValue = Number(e.target.value);

                form.setValue(field.name, currentValue);
                setPickerTimeUnitValue(currentValue);
              }}
            />
          );
        }}
      />
    ),
    [form],
  );

  const inputTypeContent = useMemo(() => {
    if (!pickerType) return null;

    return matchInputDateTypeExhaustive(pickerType, {
      dateRange: () => {
        return (
          <>
            <CustomDatePickerDateFilter
              format={CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT}
              name="startDate"
              data-lgg-id="advanced-date-filter-date-from"
              placeholder={t('common:advancedDateFilter.inputPlaceholder.fromDate')}
              label=""
              updateOnPageChange={false}
              required
            />
            <CustomDatePickerDateFilter
              format={CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT}
              name="endDate"
              data-lgg-id="advanced-date-filter-date-to"
              placeholder={t('common:advancedDateFilter.inputPlaceholder.toDate')}
              label=""
              required
              updateOnPageChange={false}
              disabledDate={(date) =>
                (pickerStartDate &&
                  date.toDate().getTime() < pickerStartDate?.getTime()) ??
                false
              }
            />
          </>
        );
      },
      inTheLast: () => {
        return (
          <>
            {timeUnitValueFormController}
            {timeUnitFormController}
          </>
        );
      },
      over: () => {
        return (
          <>
            {timeUnitValueFormController}
            {timeUnitFormController}
          </>
        );
      },
      exactDate: () => {
        return (
          <CustomDatePickerDateFilter
            format={CUSTOM_DATE_PICKER_LABEL_DATE_FORMAT}
            name="startDate"
            data-lgg-id="advanced-date-filter-exact-date"
            label=""
            updateOnPageChange={false}
            required
          />
        );
      },
      noDate: () => null,
      lastWeek: () => null,
      lastMonth: () => null,
      lastYear: () => null,
      today: () => null,
      yesterday: () => null,
      thisWeek: () => null,
      thisMonth: () => null,
      thisYear: () => null,
    });
  }, [
    pickerType,
    pickerStartDate,
    t,
    timeUnitFormController,
    timeUnitValueFormController,
  ]);

  const formContent = useMemo(() => {
    const selectedValue = options.find((value) => value.value === pickerType);

    return (
      <FormProvider {...form}>
        <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
          <FormContent data-lgg-id="advance-date-picker-form">
            <BodyContainer>
              <InputContainer>
                <CustomDatePickerSelect
                  name="advanced-date-type-select"
                  isLoading={false}
                  value={selectedValue ?? null}
                  placeholder={t('common:filters.selectOption')}
                  isSearchable={false}
                  isClearable={false}
                  forceCaret
                  onChange={(option) => {
                    form.reset();

                    if (option?.value) {
                      setPickerType(option.value);

                      computeDateValues(form.getValues() as FormValues, option.value);

                      if (['DATE_RANGE', 'EXACT_DATE'].includes(option.value)) {
                        form.setValue('startDate', null);
                        form.setValue('endDate', null);
                        setPicketStartDate(null);
                        setPickerEndDate(null);
                      }

                      if (option.value === 'DATE_RANGE') {
                        form.setValue('startDate', null);
                        form.setValue('endDate', null);
                        setPicketStartDate(null);
                        setPickerEndDate(null);
                      }

                      if (['IN_THE_LAST', 'OVER'].includes(option.value)) {
                        form.setValue('timeUnit', 'DAY');
                        setPickerTimeUnitValue(1);
                        setPickerTimeUnit('DAY');
                      }
                    }
                  }}
                  options={options}
                />
                {inputTypeContent}
              </InputContainer>
              {!breakpointUpMd && resultPreview}
            </BodyContainer>
            <CustomDatePickerPopoverFooter
              topContent={breakpointUpMd ? resultPreview : null}
              onClear={() => {
                onChange({
                  type: null,
                });
                form.clearErrors();
                setPickerType(null);
                setFieldCurrentValue(null);
                closePanel();
              }}
            />
          </FormContent>
        </StyledForm>
      </FormProvider>
    );
  }, [
    breakpointUpMd,
    closePanel,
    computeDateValues,
    form,
    inputTypeContent,
    onChange,
    onSubmit,
    options,
    resultPreview,
    pickerType,
    t,
  ]);

  const input = useMemo(
    () => (
      <GenericInput
        active={isPanelVisible}
        label={label}
        onClick={showPanel}
        value={inputValue}
        data-lgg-id={`${testId}-input`}
      />
    ),
    [inputValue, isPanelVisible, label, showPanel, testId],
  );

  return breakpointUpMd ? (
    <DropdownContainer data-lgg-id={testId}>
      <Dropdown
        overlayClassName="context-menu"
        getPopupContainer={(triggerNode) => triggerNode}
        visible={isPanelVisible}
        trigger={['click']}
        onVisibleChange={(isVisible) => {
          if (!isVisible) {
            closePanel();
          }
        }}
        placement="bottomRight"
        overlay={formContent}
      >
        {input}
      </Dropdown>
    </DropdownContainer>
  ) : (
    <>
      {input}
      <BottomDrawer
        visible={isPanelVisible}
        title={label}
        onClose={closePanel}
        push={false}
      >
        {formContent}
      </BottomDrawer>
    </>
  );
};
