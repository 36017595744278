import { useServiceSelector } from 'react-service-locator';
import { gql, useQuery } from '@apollo/client';
import { Query, QueryUserArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { useFormatDate } from 'src/hooks/use-format-date';
import { SessionService } from 'src/services/session.service';
import { SNOOZED_UNTIL_DATE_FORMAT } from 'src/utils/snooze-utils';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID!) {
    user(id: $id) {
      id
      receiveMobileNotificationsAt
    }
  }
`;

export const usePushNotificationSnoozedUntil = () => {
  const userId = useServiceSelector(SessionService, (s) => s.data.user.id);
  const { formatDate } = useFormatDate();
  const { data, loading, error } = useQuery<Pick<Query, 'user'>, QueryUserArgs>(
    GET_CURRENT_USER,
    {
      variables: {
        id: userId.toString(),
      },
    },
  );

  const snoozedUntil = data?.user.receiveMobileNotificationsAt;

  if (loading || error || !snoozedUntil) {
    return null;
  }

  return formatDate(snoozedUntil, SNOOZED_UNTIL_DATE_FORMAT);
};
