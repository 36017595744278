import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import generatePicker from 'antd/es/date-picker/generatePicker';
import c from 'classnames';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import 'antd/es/calendar/style';
import { up } from 'styled-breakpoints';
import styled, { createGlobalStyle } from 'styled-components';
import { dayjs, Dayjs } from '@lgg/isomorphic/dayjs';
import { Icon } from 'src/components/general/icon';
import { CalendarDatePicker } from 'src/components/general/inputs/calendar-date-picker';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';
import { inputFocusBorder, labelTextStyle } from 'src/theme/sub-themes/inputs-theme';

const BaseDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

const PickPanelStyle = createGlobalStyle`
  .lgg-date-picker-dropdown {
    .ant-picker-panel-container {
      border-radius: 4px;

      .ant-picker-calendar {
        margin: 0;
        border: none;
        border-radius: 4px;
        box-shadow: 0 20px 40px 0 rgba(91, 101, 112, 0.1);
        border: solid 1px ${({ theme }) => theme.colors.koala};
        overflow: hidden;
      }
    }
  }
`;

const ModalDatePicker = styled(LggModal)`
  margin: 0 auto;
  padding-bottom: 0;
  max-width: max-content;
  height: max-content;

  .ant-modal-close {
    display: none;
  }
`;

const DatePickerContainer = styled.div`
  width: 100%;
`;

const Label = styled.span`
  ${labelTextStyle}
`;

const StyledDatePicker = styled(BaseDatePicker)`
  height: 40px;
  color: ${({ theme }) => theme.colors.smalt};
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  width: 100%;
  box-shadow: none;

  input {
    color: ${({ theme }) => theme.colors.smalt};
    font-size: 13px;
  }

  &.ant-picker-focused,
  &.ant-picker-focused:hover {
    ${inputFocusBorder}
  }

  &:hover {
    border: solid 1px ${({ theme }) => theme.colors.koala};
  }

  &.ant-picker-disabled {
    border: solid 1px ${({ theme }) => theme.colors.koala};
    background: ${({ theme }) => theme.colors.porcelain};
    color: ${({ theme }) => theme.colors.geyser};

    input,
    svg {
      opacity: 50%;
    }
  }

  &.input-error {
    ${({ theme }) => theme.inputsTheme.errorBorder};
  }
`;

const StyledSuffixIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.casper};
  }
`;

type Props = {
  onChange: (date) => void;
  value?: Date;
  label?: string;
  disabled?: boolean;
  format?: string;
  placeHolder?: string;
  hasError?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  updateOnPageChange?: boolean;
};

export const DatePicker = ({
  onChange,
  value,
  label,
  disabled = false,
  format = 'YYYY-MM-DD hh:mm A',
  placeHolder,
  hasError,
  disabledDate,
  updateOnPageChange,
  ...rest
}: Props) => {
  const panelVisibilityHandler = useVisible();
  const { t } = useTranslation(['common']);
  const breakpointUpMd = useBreakpoint(up('md'));

  const calendarDatePicker = useMemo(
    () => (
      <CalendarDatePicker
        variant="small"
        setDatePickerDate={(date) => {
          onChange(date);
        }}
        isPanelVisible={panelVisibilityHandler.visible}
        datePickerDate={value}
        disabledDate={disabledDate}
        updateOnPageChange={updateOnPageChange}
        onSelect={(date, mode) => {
          onChange(date);

          if (mode === 'month') {
            panelVisibilityHandler.close();
          }
        }}
      />
    ),
    [disabledDate, onChange, panelVisibilityHandler, updateOnPageChange, value],
  );

  return (
    <DatePickerContainer className="lgg-date-picker" {...rest}>
      <Label>{label}</Label>
      <PickPanelStyle />
      <StyledDatePicker
        getPopupContainer={(targetElement) => targetElement}
        value={value ? dayjs(value) : undefined}
        className={c({
          'input-error': hasError,
        })}
        disabled={disabled}
        format={format}
        placeholder={placeHolder ?? t('common:inputPlaceholder.date')}
        open={panelVisibilityHandler.visible}
        onFocus={() => {
          if (breakpointUpMd) panelVisibilityHandler.show();
        }}
        onClick={() => panelVisibilityHandler.show()}
        onBlur={() => {
          if (breakpointUpMd) panelVisibilityHandler.close();
        }}
        suffixIcon={<StyledSuffixIcon type="calendarNoPadding" />}
        allowClear={false}
        onChange={onChange}
        dropdownClassName="lgg-date-picker-dropdown"
        panelRender={() => (breakpointUpMd ? calendarDatePicker : null)}
      />
      {breakpointUpMd ? null : (
        <ModalDatePicker
          visible={panelVisibilityHandler.visible}
          onClose={panelVisibilityHandler.close}
        >
          {calendarDatePicker}
        </ModalDatePicker>
      )}
    </DatePickerContainer>
  );
};
