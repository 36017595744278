import { z } from 'zod';

export const personalInformationSchema = z.object({
  firstName: z
    .string({ required_error: 'First name is required' })
    .min(1, 'First name must contain at least 1 character(s)'),
  lastName: z
    .string({ required_error: 'Last name is required' })
    .min(1, 'Last name must contain at least 1 character(s)'),
  email: z
    .string({ required_error: 'Email is required' })
    .email({ message: 'Invalid email address' }),
  phone: z.string({ required_error: 'Phone is required' }), //TODO: Validate phone number
  phoneExt: z.string().nullish().optional(),
  phone2: z.string({ required_error: 'Alternative phone is required' }), //TODO: Validate phone number
  phone2Ext: z.string().nullish().optional(),
});

export type PersonalInformationFormValues = z.infer<typeof personalInformationSchema>;

export const languageSchema = z.object({
  language: z.string({ required_error: 'Language is required' }),
});

export type LanguagePreferenceFormValues = z.infer<typeof languageSchema>;

export const passwordManagementSchema = z
  .object({
    currentPassword: z
      .string({ required_error: 'Current password is required' })
      .min(6, { message: 'Current password must be at least 6 characters long' }),
    newPassword: z
      .string({ required_error: 'New password is required' })
      .min(6, { message: 'New password must be at least 6 characters long' }),
    confirmNewPassword: z
      .string({ required_error: 'Confirm new password is required' })
      .min(6, { message: 'Confirm password must be at least 6 characters long' }),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: 'Passwords must match',
    path: ['confirmNewPassword'],
  });

export type PasswordManagementFormValues = z.infer<typeof passwordManagementSchema>;
