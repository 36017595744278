import { up } from 'styled-breakpoints';
import { css } from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';
import {
  errorAlertStyles,
  infoAlertStyles,
  successAlertStyles,
  warningAlertStyles,
} from 'src/components/general/feedback/shared';
import { overlayShapeStyles } from 'src/theme/sub-themes/overlays-theme';

const popovers = css`
  .lgg-popover {
    &.ant-popover {
      z-index: 999;
    }

    &.arrow-hidden {
      .ant-popover-content .ant-popover-arrow {
        display: none;
      }
    }

    .ant-popover-inner {
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 0 20px 40px 0 #5b65701a;
      border-radius: 8px;
      border: solid 1px ${({ theme }) => theme.colors.koala};
    }

    .ant-popover-content > .ant-popover-arrow {
      display: block;
      border: 1px solid ${({ theme }) => theme.colors.koala};
      background: ${({ theme }) => theme.colors.white};
      position: absolute;
      box-shadow: none;
    }

    &.ant-popover-placement-top,
    &.ant-popover-placement-topLeft,
    &.ant-popover-placement-topRight {
      .ant-popover-arrow {
        border-left: none;
        border-top: none;
        bottom: 6px;
      }
    }

    &.ant-popover-placement-bottom,
    &.ant-popover-placement-bottomLeft,
    &.ant-popover-placement-bottomRight {
      .ant-popover-arrow {
        border-right: none;
        border-bottom: none;
      }
    }

    &.ant-popover-placement-left,
    &.ant-popover-placement-leftTop,
    &.ant-popover-placement-leftBottom {
      .ant-popover-arrow {
        border-left: none;
        border-bottom: none;
      }
    }

    &.ant-popover-placement-right,
    &.ant-popover-placement-rightTop,
    &.ant-popover-placement-rightBottom {
      .ant-popover-arrow {
        border-right: none;
        border-top: none;
      }
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .sms-counter-popover {
    .ant-popover-arrow {
      background: #f4f6f8 !important;
    }
  }
`;

export const errorBoundaryModalError = css`
  .error-boundary-modal {
    max-width: calc(100% - 40px);

    .ant-modal-content {
      border-radius: 6px;

      .ant-modal-body {
        padding: 50px 20px 30px;

        .ant-modal-confirm-content {
          margin: 0;
        }

        .ant-modal-confirm-btns {
          display: none;
        }
      }
    }
  }
`;

export const confirmModal = css`
  .confirm-modal {
    max-width: calc(100% - 40px);
    max-height: 207px;

    .ant-modal-content {
      border-radius: 6px;

      .ant-modal-body {
        padding: 0;

        .ant-modal-confirm-body {
          padding: 20px;

          .ant-modal-confirm-title {
            font-family: ${({ theme }) => theme.font.regular};
            font-size: 24px;
            line-height: 1.33;
            letter-spacing: -0.48px;
            text-align: left;
            color: ${({ theme }) => theme.colors.carbon};
            margin-bottom: 10px;
          }

          .ant-modal-confirm-content {
            margin: unset;
            font-family: ${({ theme }) => theme.font.regular};
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: left;
            color: ${({ theme }) => theme.colors.smalt};
          }
        }

        .ant-modal-confirm-btns {
          display: flex;
          justify-content: end;
          width: 100%;
          margin-top: unset;
          padding: 15px;
          border-top: 1px solid ${({ theme }) => theme.colors.koala};

          .ant-btn {
            border: unset;
            border-radius: 4px;
            font-family: ${({ theme }) => theme.font.medium};
            font-size: 12px;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
          }

          button:nth-child(1) {
            background-color: ${({ theme }) => theme.colors.porcelain};
            color: ${({ theme }) => theme.colors.flint};

            &:hover,
            &:focus {
              background-color: #f4f6f8;
              color: ${({ theme }) => theme.colors.steel};
            }
          }

          .ant-btn-dangerous {
            background-color: ${({ theme }) => theme.colors.secondaryCoral};
            color: ${({ theme }) => theme.colors.white};

            &:hover,
            &:focus {
              background-color: ${({ theme }) => theme.colors.secondaryCoral80};
            }
          }
        }
      }
    }
  }
`;

export const modals = css`
  .ant-modal-mask {
    background-color: ${({ theme }) => theme.colors.storm}B3;
  }
`;

export const tooltips = css`
  .ant-tooltip-inner {
    background-color: rgba(48, 68, 87, 0.9);
    border-radius: 4px;
    font-size: 11px;
    min-height: 29px;
    padding: 7px 9px;
  }

  /* Button Tooltip */
  .ant-tooltip.button-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          top: -6px;
          width: 12.9px;
          height: 12.9px;
          background: rgba(48, 68, 87, 0.9);
          box-shadow: none;
        }
      }

      .ant-tooltip-inner {
        background: rgba(48, 68, 87, 0.9);
        line-height: 13px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const dropdowns = css`
  .ant-dropdown.context-menu-v2 {
    ${overlayShapeStyles};
    padding: 0;
  }
`;

export const tabs = css`
  /* Simulate bold text without increasing text container size */
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
    -webkit-text-stroke: 0.25px currentColor;
  }

  /* Prevent change color when pressing tab item */
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: currentColor;
  }
`;

export const notifications = css`
  .ant-notification {
    z-index: ${ModalsZIndex};
  }

  .ant-notification-notice + .ant-notification-notice {
    margin-top: 10px;
  }

  .ant-notification-notice-icon {
    line-height: 0;
    margin: 0;
  }

  .ant-notification-notice.ant-notification-notice-closable {
    border-radius: 6px;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 10px 20px 0 #727d8e33;
    padding: 15px;
    width: calc(100vw - 40px);
    margin-bottom: 0;

    ${up('md')} {
      width: 100%;
      max-width: 380px;
    }
  }
  .ant-notification-hook-holder {
    border-radius: 6px;
    ${up('md')} {
      max-width: 380px;
    }
  }

  .ant-notification-notice.lgg-success-notification.ant-notification-notice-closable {
    ${successAlertStyles};
    background-color: #ffffff;
  }

  .ant-notification-notice.lgg-error-notification.ant-notification-notice-closable {
    ${errorAlertStyles};
  }

  .ant-notification-notice.lgg-warning-notification.ant-notification-notice-closable {
    ${warningAlertStyles};
  }

  .ant-notification-notice.lgg-info-notification.ant-notification-notice-closable {
    ${infoAlertStyles};
  }

  .ant-notification-notice-with-icon .ant-notification-notice-description,
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-left: 34px;
    margin-bottom: 0;
  }

  .ant-notification-notice-close {
    right: 16px;
    margin-top: 3px;
  }

  .ant-notification-close-x {
    display: block;
    line-height: 0;
  }
`;

export const antd4OverrideGlobalStyle = css`
  ${popovers}
  ${modals}
  ${tooltips}
  ${dropdowns}
  ${tabs}
  ${errorBoundaryModalError}
  ${confirmModal}
  ${notifications}
`;
