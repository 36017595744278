import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { MenuItem } from 'src/components/domain/users/components/profile-settings-modal/components/menu-item';
import {
  SettingsOptionsKeys,
  SettingsOptionsSection,
  useProfileSettings,
} from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';

const DrawerContent = styled.div`
  padding: 16px;
`;

const MenuList = styled.nav`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BackIcon = styled(Icon)`
  margin-right: 9px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 15px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};

  button {
    min-width: 158px;
  }
`;

export const ProfileSettingsModalMobileView = memo(() => {
  const {
    currentView,
    setCurrentView,
    settingsOptions,
    formId,
    contextHolder,
    visibilityHandler,
  } = useProfileSettings('menu');

  const { t } = useTranslation(['user']);

  const currentSection: SettingsOptionsSection = settingsOptions[currentView as string];

  return (
    <BottomDrawer
      fullHeight
      data-lgg-id="profile-settings-modal"
      visible={visibilityHandler.visible}
      title={currentSection?.title || t('user:profileSettings.title')}
      onClose={visibilityHandler.close}
      showCloseIcon={currentView === 'menu'}
      leading={
        currentView !== 'menu' && (
          <BackIcon
            type="iconBackTop"
            onClick={() => setCurrentView('menu')}
            aria-label="Back to menu"
          />
        )
      }
      footer={
        currentView !== 'menu' && (
          <FooterContainer>
            <ButtonV2
              size="regular"
              variant="default"
              data-lgg-id="cancel-button"
              form={formId}
              type="reset"
            >
              {t('user:profileSettings.buttons.cancel')}
            </ButtonV2>
            <ButtonV2
              size="regular"
              variant="primary"
              data-lgg-id="submit-button"
              form={formId}
              type="submit"
            >
              {t('user:profileSettings.buttons.save')}
            </ButtonV2>
          </FooterContainer>
        )
      }
      aria-label="Profile settings menu"
    >
      <DrawerContent>
        {contextHolder}
        {match(currentView)
          .with('menu', () => (
            <MenuList aria-label="Profile settings options">
              {Object.entries(settingsOptions).map(([key, { icon, title }]) => (
                <MenuItem
                  key={key}
                  icon={icon}
                  title={title}
                  onClick={() => setCurrentView(key as SettingsOptionsKeys)}
                  rightIcon={<Icon type="arrowright" />}
                />
              ))}
            </MenuList>
          ))
          .otherwise(() => currentSection?.content)}
      </DrawerContent>
    </BottomDrawer>
  );
});
