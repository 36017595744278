import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactInteractionChannelSlug,
  ContactInteractionWhereInput,
  Query,
  QueryContactInteractionsArgs,
  Schedule,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactInteractionDirection } from 'src/components/domain/contact-interaction/helpers';
import {
  LggDropdownButtonWithoutOverlay,
  LggSelectableOptionsDropdownButton,
} from 'src/components/general/button/dropdown-button';
import { ExportResultsButton } from 'src/components/general/button/export-results-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { GraphqlTablePagination } from 'src/components/general/display/graphql-table-pagination';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import {
  TableBodyPlaceholder,
  TableLayoutPlaceholder,
} from 'src/components/general/feedback/loading-placeholders';
import {
  BasePageQueryParams,
  getSortDirectionByOrder,
  SorterData,
  TableLayoutHeader,
  tableLayoutViewButtonStyles,
  TableSortData,
} from 'src/components/general/table-helpers';
import { TableLayoutPageContainer } from 'src/components/layout/table-layout-page-container';
import {
  ActivityFilters,
  ActivityFiltersFormValues,
  defaultActivityFilters,
} from 'src/components/pages/activity/components/activity-filters';
import { ActivityTable } from 'src/components/pages/activity/components/activity-table';
import { CORE_PAGE_INFO_FIELDS } from 'src/components/providers/apollo-provider-provider';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import {
  LEGACY_DATE_PARAM_FORMAT,
  useLegacyParamsForDashboardQuery,
  usePatchRawQueryParams,
  viewCodeQueryParamKey,
} from 'src/hooks/use-legacy-params-for-dashboard-query';
import { usePushUrlWithViewCode } from 'src/hooks/use-push-url-with-view-code';
import { useVisible } from 'src/hooks/use-visible';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const ACTIVITY_QUERY_PAGE_SIZE = 50;

const MEDIUMS: ContactInteractionChannelSlug[] = [
  'CALL',
  'SMS',
  'EMAIL',
  'FACEBOOK_AD',
  'FACEBOOK_MESSENGER',
  'WALK_IN',
  'WHATSAPP',
];

const GET_CONTACT_INTERACTIONS_QUERY = gql`
  ${CORE_PAGE_INFO_FIELDS}
  query getContactInteractions(
    $institutionId: Int!
    $where: ContactInteractionWhereInput
    $orderBy: ContactInteractionOrderByInput
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    contactInteractions(
      institutionId: $institutionId
      first: $first
      after: $after
      before: $before
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ... on ContactInteraction {
            id
            occurredAt
            description
            participatingUser {
              id
              fullName
            }
            isCreationAutomatic
            source {
              id
              name
            }
            campaign {
              id
              name
            }
            department {
              id
              name
            }
            contact {
              id
              label
              interest
              primaryEmail
              primaryPhone {
                national
                e164
              }
              lastContactInteraction {
                id
                occurredAt
              }
              tags {
                id
                name
                isActive
              }
              status {
                id
                name
              }
              stage {
                id
                name
                slug
              }
              assignee {
                id
                fullName
                avatar {
                  color
                  initials
                }
                role {
                  id
                  name
                }
              }
            }
          }
          ... on ContactInteractionWithDirection {
            direction
          }
          ... on ContactInteractionPhoneCall {
            callDetail {
              duration
              inboundLastStep
              outboundDialUnansweredReason
              isInProgress
            }
            direction
            dialStatus
            answeredPhone {
              national
            }
            recording {
              url
            }
          }
        }
      }
    }
  }
`;

type ActivityPageAdvancedQueryParams = Omit<
  ActivityFiltersFormValues,
  | 'department'
  | 'assigned_unassigned'
  | 'Owner'
  | 'answered_by'
  | 'campaign'
  | 'channel'
  | 'medium'
  | 'tag'
> & {
  department?: string;
  assigned_unassigned?: string;
  Owner?: string;
  answered_by?: string;
  campaign?: string;
  channel?: string;
  medium?: string;
  tag?: string;
};

enum ActivityViewCodeFilter {
  Last30Days = 'last-30-days',
  Custom = 'custom',
}

type ActivityPageQueryParams = BasePageQueryParams<
  'custom' | 'last-30-days',
  ActivityPageAdvancedQueryParams
>;

const DesktopViewButton = styled(LggSelectableOptionsDropdownButton)`
  ${tableLayoutViewButtonStyles}
`;

const MobileViewButton = styled(LggDropdownButtonWithoutOverlay)`
  ${tableLayoutViewButtonStyles}
`;

const sortInputResolver = (sortData: TableSortData) => {
  const { key, direction = 'DESC' } = sortData;

  switch (key) {
    case 'contact': {
      return {
        contact: {
          firstName: direction,
        },
      };
    }
    case 'assignee': {
      return {
        contact: {
          assignee: {
            firstName: direction,
          },
        },
      };
    }
    default: {
      return {
        [key]: direction,
      };
    }
  }
};

const filtersStateResolver = (
  queryParams: ActivityPageQueryParams,
): Partial<ActivityFiltersFormValues> | undefined => {
  if (!queryParams.q?.advanced) {
    return undefined;
  }

  const { advanced } = queryParams.q;

  const values: Partial<ActivityFiltersFormValues> = {};

  if (advanced.date_from) {
    values.date_from = advanced.date_from;
  }

  if (advanced.date_to) {
    values.date_to = advanced.date_to;
  }

  if (advanced.contact) {
    values.contact = advanced.contact;
  }

  if (advanced.medium_status) {
    values.medium_status = advanced.medium_status;
  }

  if (advanced.department) {
    values.department = Number(advanced.department);
  }

  if (advanced.assigned_unassigned) {
    values.assigned_unassigned = Number(advanced.assigned_unassigned);
  }

  if (advanced.Owner) {
    values.Owner = Number(advanced.Owner);
  }

  if (advanced.answered_by) {
    values.answered_by = Number(advanced.answered_by);
  }

  if (advanced.campaign) {
    values.campaign = Number(advanced.campaign);
  }

  if (advanced.source) {
    values.source = Number(advanced.source);
  }

  if (advanced.medium) {
    values.medium = Number(advanced.medium);
  }

  if (advanced.direction) {
    values.direction = advanced.direction;
  }

  if (advanced.tag) {
    values.tag = Number(advanced.tag);
  }

  return values;
};

const useWhereInputResolver = (viewCode: ActivityPageQueryParams['view-code']) => {
  const { startOfDay, endOfDay, parseISO } = useDateHelpers();

  return (params: ActivityPageQueryParams) => {
    const whereClauses: ContactInteractionWhereInput[] = [];
    if (params.q?.advanced) {
      const { advanced } = params.q;

      if (advanced.date_from) {
        const parsedDateFrom = parseISO(advanced.date_from);

        whereClauses.push({
          occurredAt: {
            _gte: startOfDay(parsedDateFrom),
          },
        });
      }

      if (advanced.date_to) {
        const parsedDateTo = parseISO(advanced.date_to);

        whereClauses.push({
          occurredAt: {
            _lte: endOfDay(parsedDateTo),
          },
        });
      }

      if (advanced.contact) {
        whereClauses.push({
          contact: {
            matchKeywords: {
              _all: advanced.contact,
            },
          },
        });
      }

      if (advanced.medium_status) {
        whereClauses.push({
          leadHistoryStatus: {
            _eq: advanced.medium_status,
          },
        });
      }

      if (advanced.department) {
        whereClauses.push({
          department: {
            id: {
              _eq: Number(advanced.department),
            },
          },
        });
      }

      if (advanced.assigned_unassigned) {
        const parsedValue = Number(advanced.assigned_unassigned);
        const condition =
          parsedValue === 0
            ? {
                _eq: null,
              }
            : {
                _ne: null,
              };

        whereClauses.push({
          contact: {
            assignee: {
              ...condition,
            },
          },
        });
      }

      if (advanced.Owner) {
        whereClauses.push({
          contact: {
            assignee: {
              id: {
                _eq: Number(advanced.Owner),
              },
            },
          },
        });
      }

      if (advanced.answered_by) {
        whereClauses.push({
          participatingUser: {
            id: {
              _eq: Number(advanced.answered_by),
            },
          },
        });
      }

      if (advanced.source) {
        whereClauses.push({
          source: {
            id: {
              _eq: Number(advanced.source),
            },
          },
        });
      }

      if (advanced.campaign) {
        whereClauses.push({
          campaign: {
            id: {
              _eq: Number(advanced.campaign),
            },
          },
        });
      }

      if (advanced.medium) {
        const parsedMediumIndex = Number(advanced.medium);
        const medium = MEDIUMS[parsedMediumIndex - 1];

        if (medium) {
          whereClauses.push({
            channel: {
              slug: {
                _eq: medium,
              },
            },
          });
        }
      }

      if (advanced.direction) {
        const direction =
          advanced.direction === 'INBOUND'
            ? ContactInteractionDirection.INBOUND
            : ContactInteractionDirection.OUTBOUND;

        whereClauses.push({
          direction: {
            _eq: direction,
          },
        });
      }

      if (advanced.tag) {
        whereClauses.push({
          contact: {
            tags: {
              id: {
                _eq: Number(advanced.tag),
              },
            },
          },
        });
      }
    }

    return whereClauses.length ? { _and: whereClauses } : undefined;
  };
};

const useViewCodeResolver = (viewCode: ActivityPageQueryParams['view-code']) => {
  const { startOfDay, subDays, format, getNowDateAsInTimezone } = useDateHelpers();
  const nowDate = useMemo(
    () => getNowDateAsInTimezone(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNowDateAsInTimezone, viewCode],
  );
  const thirtyDaysAgoDate = useMemo(
    () => format(subDays(startOfDay(nowDate), 30), LEGACY_DATE_PARAM_FORMAT),
    [format, nowDate, startOfDay, subDays],
  );

  return (rawQueryParams: ActivityPageQueryParams): ActivityPageQueryParams => {
    const viewCode = rawQueryParams[viewCodeQueryParamKey];
    let advanceParams: ActivityPageAdvancedQueryParams = {};

    switch (viewCode) {
      case 'last-30-days': {
        advanceParams = {
          date_from: thirtyDaysAgoDate,
        };
        break;
      }
      case 'custom':
        break;
    }

    if (Object.keys(advanceParams)) {
      return {
        ...rawQueryParams,
        q: {
          advanced: advanceParams,
        },
      };
    } else {
      return rawQueryParams;
    }
  };
};

export const ActivityPage = memo(() => {
  const { t } = useTranslation(['common', 'activity']);
  useAddBreadcrumb(t('activity:pageBreadcrumb'));
  const { id: institutionId } = useCurrentInstitution();
  const institutionUrl = useInstitutionUrl();
  const handleGraphQLError = useHandleGraphQLError();
  const filtersVisibilityHandler = useVisible();
  const rawQueryParams = usePatchRawQueryParams<
    ActivityPageAdvancedQueryParams,
    ActivityPageQueryParams['view-code']
  >({ defaultViewCode: ActivityViewCodeFilter.Last30Days });
  const viewCode = rawQueryParams['view-code'];
  const viewCodeResolver = useViewCodeResolver(viewCode);
  const breakpointUpMd = useBreakpoint(up('md'));
  const viewCodeDropdownVisibilityHandler = useVisible();
  const { pushUrlWithViewCode } =
    usePushUrlWithViewCode<ActivityPageQueryParams['view-code']>();
  const whereInputResolver = useWhereInputResolver(viewCode);

  const {
    variables,
    hasActiveFilters,
    filters,
    handleNextPage,
    handlePreviousPage,
    sortData,
    legacyFiltersQueryString,
    handleSortChange,
  } = useLegacyParamsForDashboardQuery({
    pageSize: ACTIVITY_QUERY_PAGE_SIZE,
    defaultFilters: defaultActivityFilters,
    dateFilterKeys: ['date_from', 'date_to'],
    defaultSortData: {
      key: 'occurredAt',
      direction: 'DESC',
    },
    rawQueryParams,
    whereInputResolver,
    sortInputResolver,
    filtersStateResolver,
    viewCodeResolver,
  });

  const selectedViewCodeDescription = useMemo(() => {
    switch (viewCode) {
      case 'last-30-days': {
        return t('activity:viewFilter.options.last30Days');
      }
      case 'custom': {
        return t('common:custom');
      }
    }
  }, [t, viewCode]);

  const {
    data: contactInteractionsData,
    loading: loadingContactInteractions,
    networkStatus,
    error,
  } = useQuery<Pick<Query, 'contactInteractions'>, Partial<QueryContactInteractionsArgs>>(
    GET_CONTACT_INTERACTIONS_QUERY,
    {
      variables: {
        ...variables,
        institutionId,
      },
      onError: handleGraphQLError,
    },
  );

  const isFetchingMore = networkStatus === NetworkStatus.setVariables;
  const isRefetching = networkStatus === NetworkStatus.refetch;

  if (loadingContactInteractions && !isRefetching && !contactInteractionsData) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  if (error) return <div>error</div>;
  if (!contactInteractionsData) return null;

  const {
    contactInteractions: { pageInfo, totalCount },
  } = contactInteractionsData;
  const contactInteractions = getNodesFromConnection(
    contactInteractionsData.contactInteractions,
  );

  const viewOptions = [
    {
      label: t('activity:viewFilter.options.last30Days'),
      'data-lgg-id': 'activity-page-view-button-option-last-30-days',
      value: ActivityViewCodeFilter.Last30Days,
      onClick: () => {
        pushUrlWithViewCode(ActivityViewCodeFilter.Last30Days);
      },
    },
  ];

  const viewButton = breakpointUpMd ? (
    <DesktopViewButton
      visibilityHandler={viewCodeDropdownVisibilityHandler}
      data-lgg-id="activity-page-view-button"
      selectedValue={viewCode}
      customDropdownProps={{
        overlayStyle: {
          minWidth: '141px',
        },
      }}
      options={viewOptions}
      variant="defaultWhite"
      size="regular"
    >
      {selectedViewCodeDescription}
    </DesktopViewButton>
  ) : (
    <>
      <MobileViewButton
        data-lgg-id="activity-page-view-button"
        variant="defaultWhite"
        size="regular"
        onClick={() =>
          viewCodeDropdownVisibilityHandler.setVisible(
            !viewCodeDropdownVisibilityHandler.visible,
          )
        }
        isActive={viewCodeDropdownVisibilityHandler.visible}
      >
        {selectedViewCodeDescription}
      </MobileViewButton>
      <SelectableOptionsDrawer
        title={selectedViewCodeDescription}
        options={viewOptions}
        selectedValue={viewCode}
        onClose={viewCodeDropdownVisibilityHandler.close}
        visible={viewCodeDropdownVisibilityHandler.visible}
      />
    </>
  );

  return (
    <TableLayoutPageContainer data-lgg-id="activity-page-container">
      <TableLayoutHeader
        leftContent={viewButton}
        rightContent={
          <>
            <FiltersButton
              filtersVisibilityHandler={filtersVisibilityHandler}
              hasActiveFilters={hasActiveFilters}
              data-lgg-id="activity-page-filters-button"
            />
            <ExportResultsButton
              testId="activity-page-export"
              requestUrl={`${institutionUrl}activity/`}
              params={legacyFiltersQueryString}
            />
          </>
        }
        bottomContent={
          <PaginationInfo
            total={totalCount}
            testId="activity-page-pagination-info"
            entityName={t('activity:activity', { count: totalCount })}
          />
        }
      />
      {isFetchingMore ? (
        <TableBodyPlaceholder />
      ) : (
        <>
          <ActivityTable
            interactions={contactInteractions}
            sortData={sortData}
            onChange={(pagination, filters, sorter) => {
              const { columnKey, order } = sorter as SorterData<Schedule>;
              const sortDirection = getSortDirectionByOrder(order);
              handleSortChange({ columnKey, sortDirection });
            }}
          />
          <GraphqlTablePagination
            previousPageHandler={handlePreviousPage}
            nextPageHandler={handleNextPage}
            pageInfo={pageInfo}
          />
          <ActivityFilters
            visible={filtersVisibilityHandler.visible}
            onClose={filtersVisibilityHandler.close}
            filters={filters}
          />
        </>
      )}
    </TableLayoutPageContainer>
  );
});
