import { useMemo } from 'react';
import { useInstagramResourceActivation } from 'src/components/pages/instagram-channel/shared/hooks';
import { useMetaResourceActivation } from 'src/components/pages/whatsapp-channel/shared/hooks';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useSetHeaderActions } from 'src/hooks/use-set-header-actions';
import { HeaderAction } from 'src/services/header-actions.service';

const resourcesRegExp = new RegExp('^.*tracking/resources/?$');

export function useSetLegacyHeaderActions(headerActionsElement: HTMLDivElement | null) {
  const { getFeatureFlag } = useAuthorization();
  const { launchWhatsAppSignUp } = useMetaResourceActivation();
  const { launchInstagramPermissionGrant } = useInstagramResourceActivation();

  const isResourcePage = resourcesRegExp.test(window.location.pathname);

  const isResourcePageAndWhatsAppActivated =
    isResourcePage &&
    getFeatureFlag(
      'temp_lggdev-1706_como-usuario-deseo-poder-agregar-nuevos-numeros-de-whatsapp-a-traves-de-leadgogo',
    );
  const isResourcePageAndInstagramActivated =
    isResourcePage && getFeatureFlag('channel_instagram');

  const actions = useMemo(() => {
    const extraLinks: HeaderAction[] = [];
    if (isResourcePageAndWhatsAppActivated) {
      extraLinks.push({
        label: 'WhatsApp Number',
        icon: 'whatsapp',
        type: 'button',
        href: '',
        onClick: launchWhatsAppSignUp,
        isLegacy: false,
      });
    }

    if (isResourcePageAndInstagramActivated) {
      extraLinks.push({
        label: 'Instagram Account',
        icon: 'instagram',
        type: 'button',
        href: '',
        onClick: launchInstagramPermissionGrant,
        isLegacy: false,
        color: 'smalt',
      });
    }

    return extraLinks;
  }, [
    isResourcePageAndWhatsAppActivated,
    isResourcePageAndInstagramActivated,
    launchWhatsAppSignUp,
    launchInstagramPermissionGrant,
  ]);

  const legacyActions = useMemo(() => {
    if (headerActionsElement) {
      const text = headerActionsElement.innerText;
      const arr = JSON.parse(text) as HeaderAction[];

      for (const action of arr) {
        const parsedUrl = new URL(action.href, new URL(window.location.href).origin);
        action.href = `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
        action.isLegacy = true;
      }
      return arr;
    }
    return [];
  }, [headerActionsElement]);

  useSetHeaderActions([...actions, ...legacyActions]);
}
