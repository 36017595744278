import React, { memo, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled, { css, keyframes } from 'styled-components';
import { match, P } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  ContactInteractionUnion,
  ContactInteractionDirection,
  LeadHistoryLogStatus,
  ContactInteractionComponent,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ManualInteractionIcon } from 'src/components/domain/contact-interaction/manual-interaction-icon';
import { useOutstandingItem } from 'src/components/general/display/inverted-virtuoso';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { Center } from 'src/components/layout/center';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  AttachmentOverlay,
  PlayIcon,
} from 'src/components/pages/conversations/components/contact-interactions/items/attachment-items/shared';
import {
  ContactInteractionDetails,
  ContactInteractionDetailsPopoverData,
} from 'src/components/pages/conversations/components/contact-interactions/items/contact-interaction-details';
import { InteractionTime } from 'src/components/pages/conversations/components/contact-interactions/items/interaction-time';
import { RepliedItemPreview } from 'src/components/pages/conversations/components/contact-interactions/items/replied-item-preview';
import { useVisible } from 'src/hooks/use-visible';
import { fadeInAnimation } from 'src/theme/animations';

export const messageBubbleMaxWidthStyle = css`
  max-width: ${({ isNarrow }: { isNarrow?: boolean }) =>
    isNarrow ? 'min(80%, 310px)' : 'min(80%, 565px)'};
`;

export const messageBubblesStyles = css`
  ${messageBubbleMaxWidthStyle};
  box-shadow: 0 2px 4px 0 #98a9bc0f;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  position: relative;
`;

export const BubbleDivider = styled.div<{ direction: 'INBOUND' | 'OUTBOUND' }>`
  background-color: ${({ theme, direction }) =>
    direction === 'INBOUND' ? theme.colors.koala : theme.colors.flint};
  height: 1px;
  margin: 15px -15px;
  opacity: ${({ direction }) => (direction === 'INBOUND' ? 1 : 0.2)};

  ${up('md')} {
    background-color: ${({ theme, direction }) =>
      direction === 'INBOUND' ? theme.colors.porcelain : theme.colors.flint};
  }
`;

export const InteractionTextContent = styled.p<{ direction: 'INBOUND' | 'OUTBOUND' }>`
  color: ${({ theme, direction }) =>
    direction === 'INBOUND' ? theme.colors.storm : theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 15px;
  margin: 0 0 10px;

  ${up('md')} {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 16px;
  }
`;

export const ciHighlightedAnimation = keyframes`
  0% {
    outline: 2px solid transparent;
    outline-offset: -3px;
  }

  33% {
    outline: 2px solid rgba(45, 152, 218, 0.5);
    outline-offset: 1px;

  }

  66% {
    outline-offset: 2px;
  }

  100% {
    outline: 2px solid transparent;
    outline-offset: 4px;
  }
`;

const noteHighlightedAnimation = keyframes`
  0% {
    outline: 2px solid transparent;
    outline-offset: -3px;
  }

  33% {
    outline: 2px solid rgba(255, 185, 0, 0.75);
    outline-offset: 1px;

  }

  66% {
    outline-offset: 2px;
  }

  100% {
    outline: 2px solid transparent;
    outline-offset: 4px;
  }
`;

export const MessageBubbleWithDirectionContainer = styled.div<{
  direction: ContactInteractionDirection;
  isCreationAutomatic: boolean;
  backgroundColor?: ColorPaletteItem;
  forceMessageCaret?: Boolean;
  isNarrow?: boolean;
}>`
  ${messageBubblesStyles};
  background-color: ${({ direction, backgroundColor, theme }) =>
    backgroundColor
      ? theme.colors[backgroundColor]
      : direction === 'INBOUND'
      ? theme.colors.porcelain
      : theme.colors.topaz20};
  border-radius: ${({ direction, isCreationAutomatic, forceMessageCaret }) =>
    !forceMessageCaret && !isCreationAutomatic
      ? '6px'
      : direction === 'INBOUND'
      ? '8px 8px 8px 0'
      : '8px 8px 0 8px'};
  display: flex;

  & > * {
    border-radius: inherit;
    padding: 15px 15px 10px;
  }

  ${({ direction }) =>
    direction === 'INBOUND' ? 'margin-right: auto;' : 'margin-left: auto;'};

  ${up('md')} {
    background-color: ${({ direction, backgroundColor, theme }) =>
      backgroundColor
        ? theme.colors[backgroundColor]
        : direction === 'INBOUND'
        ? theme.colors.white
        : theme.colors.topaz20};
  }

  &.ci-highlighted {
    animation: ${ciHighlightedAnimation} 1.4s 4;
  }

  &.note-highlighted {
    animation: ${noteHighlightedAnimation} 1.4s 4;
  }
`;

const CenteredContainer = styled(FlexRow)`
  align-items: center;
`;

const DetailsMobileTrigger = styled.span`
  ${fadeInAnimation};
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.flint};
  font-size: 10px;
  margin-left: 5px;
  line-height: 12px;
  position: relative;
  top: 1px;

  .separator {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  ${up('md')} {
    display: none;
  }
`;

const StatusIcon = styled(Icon)`
  margin-left: 5px;

  svg {
    max-height: 20px;
    max-width: 20px;
  }
`;

export const DeliveryStatusIcon = ({
  messageStatus,
}: {
  messageStatus: LeadHistoryLogStatus;
}) => {
  const iconName = match(messageStatus)
    .with('DELIVERED', () => 'messageDeliveredStatus')
    .with('SENT', () => 'messageSentStatus')
    .with('READ', () => 'messageReadStatus')
    .with('FAILED', () => null)
    .otherwise(() => 'messagePendingStatus');

  if (!iconName) return null;

  return <StatusIcon type={iconName} lggTestId="delivery-status-icon" />;
};

type MessageBubbleWithDirectionProps = {
  testId: string;
  id?: string;
  direction: ContactInteractionDirection;
  isCreationAutomatic: boolean;
  isCall?: boolean;
  children: any;
  createdAt: string;
  details: ContactInteractionDetailsPopoverData | null;
  backgroundColor?: ColorPaletteItem;
  forceMessageCaret?: boolean;
  isNote?: boolean;
  contactInteractionType?: string;
  contactInteractionLogId?: string;
  deliveryStatus?: LeadHistoryLogStatus;
  isNarrow?: boolean;
};

export const MessageBubbleWithDirection = ({
  direction,
  isCreationAutomatic,
  children,
  createdAt,
  details,
  backgroundColor,
  forceMessageCaret = false,
  testId,
  id = '',
  isCall = false,
  isNote = false,
  contactInteractionType,
  contactInteractionLogId,
  deliveryStatus,
  isNarrow = false,
  ...rest
}: MessageBubbleWithDirectionProps) => {
  const { visible, setVisible } = useVisible();
  const { t } = useTranslation(['common']);
  const { visible: showMobileDetailsTrigger, setVisible: setShowMobileDetailsTrigger } =
    useVisible();

  const { containerRef } = useOutstandingItem({ visible });

  return (
    <MessageBubbleWithDirectionContainer
      data-lgg-id={testId}
      ref={containerRef}
      data-id={id || details?.interactionId}
      isCreationAutomatic={isCreationAutomatic}
      direction={direction}
      backgroundColor={backgroundColor}
      forceMessageCaret={forceMessageCaret}
      isNarrow={isNarrow}
      {...rest}
    >
      <ContactInteractionDetails
        isCreationAutomatic={isCreationAutomatic}
        direction={direction}
        details={details}
        visible={visible}
        isNote={isNote}
        contactInteractionLogId={contactInteractionLogId}
        setVisibility={setVisible}
        isCall={isCall}
        contactInteractionType={contactInteractionType}
        onClick={() => {
          setShowMobileDetailsTrigger(!showMobileDetailsTrigger);
        }}
      >
        <div>
          {children}
          <CenteredContainer>
            <InteractionTime createdAt={createdAt} />
            {deliveryStatus ? (
              <DeliveryStatusIcon messageStatus={deliveryStatus} />
            ) : null}
            {showMobileDetailsTrigger && (
              <DetailsMobileTrigger
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(true);
                }}
              >
                <span className="separator">—</span>
                <span>{t('common:seeDetails')}</span>
              </DetailsMobileTrigger>
            )}
          </CenteredContainer>
        </div>
      </ContactInteractionDetails>
    </MessageBubbleWithDirectionContainer>
  );
};

const MessageTitle = styled.span`
  color: rgba(87, 114, 138, 0.75);
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;

  strong {
    color: ${({ theme }) => theme.colors.smalt};
    font-weight: unset;
  }
`;

const UniformTitle = styled(MessageTitle)`
  color: ${({ theme }) => theme.colors.smalt};
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

type BubbleMessageHeaderProps = {
  icon?: string;
  title: string | ReactElement;
  contactInteraction: ContactInteractionUnion;
};

export const BubbleMessageHeader = memo<BubbleMessageHeaderProps>(
  ({ title, icon, contactInteraction, ...props }) => {
    const TitleComponent = typeof title === 'string' ? UniformTitle : MessageTitle;

    return (
      <FlexRow {...props}>
        {icon && (
          <StyledIcon
            className="interaction-icon"
            type={icon}
            lggTestId={`contact-interaction-icon-${icon}`}
          />
        )}
        <TitleComponent data-lgg-id="contact-interaction-card-title">
          {title}
          <ManualInteractionIcon contactInteraction={contactInteraction} />
        </TitleComponent>
      </FlexRow>
    );
  },
);

const StyledCenteredContainer = styled(CenteredContainer)`
  margin-left: auto;
  margin-top: 8px;
  width: max-content;
  color: ${({ theme }) => theme.colors.flint};
  font-size: 10px;
  letter-spacing: -0.2px;

  span {
    margin-left: 5px;
  }
`;

type FailedMessageIndicatorProps = Partial<
  Pick<ContactInteractionComponent, 'statusDetails'>
>;

export const FailedMessageIndicator = memo<FailedMessageIndicatorProps>(
  ({ statusDetails }) => {
    const { t } = useTranslation(['conversations']);
    const error = statusDetails?.message;
    const errorComponent = useMemo(
      () => (
        <StyledCenteredContainer>
          <Icon type="infoAlertRedIcon" lggTestId="failed-interaction-status-message" />
          <span>
            {t('conversations:contactInteractionBubble.couldntSendInteraction')}
          </span>
        </StyledCenteredContainer>
      ),
      [t],
    );

    if (error) {
      return (
        <Tooltip title={error} trigger={['hover']}>
          {errorComponent}
        </Tooltip>
      );
    }

    return errorComponent;
  },
);

export const RepliedMessageRenderer = (props: {
  contactInteraction: ContactInteractionUnion;
}) => {
  const { contactInteraction, ...rest } = props;

  const relatedContactInteraction = match(contactInteraction)
    .with(
      {
        message: {
          relatedContactInteraction: P.not(P.nullish),
        },
      },
      (contactInteraction) => contactInteraction.message.relatedContactInteraction,
    )
    .with(
      {
        attachments: [
          {
            __typename: P.union(
              'ContactInteractionAttachmentFile',
              'ContactInteractionAttachmentLocation',
              'ContactInteractionAttachmentContacts',
            ),
            relatedContactInteraction: P.not(P.nullish),
          },
        ],
      },
      (contactInteraction) => {
        return 'attachments' in contactInteraction
          ? (contactInteraction.attachments[0]
              .relatedContactInteraction as ContactInteractionUnion)
          : null;
      },
    )
    .otherwise(() => null);

  if (!relatedContactInteraction) {
    return null;
  }

  return <RepliedItemPreview {...rest} contactInteraction={relatedContactInteraction} />;
};

export const ItemMessage = styled(InteractionTextContent)`
  margin-bottom: 5px;
`;

export const ItemCaption = styled(ItemMessage)`
  margin-top: 10px;
  margin-left: 5px;
`;

const ReferralItemContainer = styled.div`
  width: 100%;

  ${up('md')} {
    max-width: 290px;
  }
`;

const ReferralDisclaimer = styled.div`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  padding: 0 10px 15px;
  margin: 0 -15px;
  border-bottom: 1px solid white;

  ${up('md')} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.porcelain};
  }
`;

const LinkContainer = styled.a`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px -5px;
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-weight: 500;
  background: white;
  padding: 10px 15px;
  border-radius: 4px;

  ${up('md')} {
    background: ${({ theme }) => theme.colors.porcelain};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.storm};
  }
`;

const RefIcon = styled(Icon)`
  margin-right: 10px;

  & svg {
    height: 14px;
    width: 14px;
  }
`;

const DropdownIcon = styled(Icon)`
  & svg {
    height: 12px;
    width: 12px;
  }
`;

const ThumbnailWrapper = styled.div`
  max-width: 270px;
`;

const ThumbnailContainer = styled(Center)`
  position: relative;
`;

export const ImageWrapper = styled(Center)`
  width: 100%;
  height: max-content;
  max-height: 325px;
  min-height: 50px;
  min-width: 60px;
  overflow: hidden;
  border-radius: 6px 6px 6px 0;

  img {
    max-width: 260px;
    width: 100%;
    object-fit: cover;
  }
`;

const BodyContent = styled.p`
  margin: -6px 0 0;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  max-height: 32px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const HeadlineContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

export const ContentContainer = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  align-items: center;
`;

const Headline = styled.span<{ hasHeadline: boolean }>`
  color: ${({ theme, hasHeadline }) =>
    hasHeadline ? theme.colors.smalt : theme.colors.gogo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: ${({ hasHeadline }) => `${hasHeadline ? 13 : 12}px`};
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const ReferralItemRenderer = ({
  contactInteraction,
}: {
  contactInteraction: ContactInteractionUnion;
}) => {
  const { t } = useTranslation(['conversations']);

  const referral = match(contactInteraction)
    .with(
      {
        message: {
          referral: { url: P.string },
        },
      },
      (contactInteraction) => contactInteraction.message.referral,
    )
    .otherwise(() => null);

  if (!referral) {
    return null;
  }

  const renderImageOrThumbnail = () =>
    match(referral)
      .with(
        { __typename: 'ContactInteractionReferralImage', imageUrl: P.string },
        (referral) => {
          return (
            <ImageWrapper>
              <img
                src={referral.imageUrl}
                alt={referral?.headline || 'Referral image'}
                data-lgg-id="referral-image"
              />
            </ImageWrapper>
          );
        },
      )
      .with(
        { __typename: 'ContactInteractionReferralVideo', thumbnailUrl: P.string },
        (referral) => {
          return (
            <ThumbnailWrapper>
              <ThumbnailContainer>
                <PlayIcon type="play" />
                <ImageWrapper>
                  <img
                    src={referral.thumbnailUrl}
                    alt={referral?.headline || 'Referral thumbnail'}
                    data-lgg-id="referral-thumbnail"
                  />
                </ImageWrapper>
                <AttachmentOverlay />
              </ThumbnailContainer>
            </ThumbnailWrapper>
          );
        },
      )
      .otherwise(() => null);

  const iconName = referral.source || 'externalLink';

  const sourceName = match(referral)
    .with({ source: 'instagram' }, () => 'Instagram')
    .with({ source: 'facebook' }, () => 'Facebook')
    .otherwise(() => null);

  return (
    <ReferralItemContainer>
      <ReferralDisclaimer data-lgg-id="referral-text">
        {t('conversations:referralPreview.source', {
          source: sourceName || t('conversations:referralPreview.externalSite'),
        })}
      </ReferralDisclaimer>
      <LinkContainer
        href={referral.url}
        target="_blank"
        referrerPolicy="no-referrer"
        rel="noreferrer"
      >
        {renderImageOrThumbnail()}
        <HeadlineContainer>
          <ContentContainer>
            {!referral.body && (
              <RefIcon
                type={iconName}
                data-lgg-id={`contact-interaction-icon-${iconName}`}
              />
            )}
            <Headline hasHeadline={Boolean(referral?.headline)}>
              {referral?.headline ?? t('conversations:referralPreview.link')}
            </Headline>
          </ContentContainer>
          {referral.type === 'link' && !referral.body && (
            <DropdownIcon type="arrowRightWide" />
          )}
        </HeadlineContainer>
        {referral.body && <BodyContent>{referral.body}</BodyContent>}
      </LinkContainer>
    </ReferralItemContainer>
  );
};
