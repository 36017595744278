import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Tabs } from 'antd';
import styled from 'styled-components';
import { MenuItem } from 'src/components/domain/users/components/profile-settings-modal/components/menu-item';
import {
  SettingsOptionsKeys,
  useProfileSettings,
} from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';

const StyledModal = styled(Modal)`
  height: 100%;
  max-width: 650px;
  max-height: 603px;

  .ant-modal-header {
    background-color: ${({ theme }) => theme.colors.porcelain};
    min-height: 64px;
    padding: 20px;
  }

  .ant-modal-content {
    border-radius: 6px;
    max-width: none;
    max-height: none;
  }

  .ant-modal-close .ant-modal-close-x {
    width: 64px;
    height: 64px;
    line-height: 64px;
  }

  .ant-modal-body {
    height: 100%;
    min-height: 474px;
    padding: unset;
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.colors.carbon};
  margin-bottom: unset;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap {
    max-width: 250px;
    padding: 20px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: unset;

    .ant-tabs-tab-btn {
      width: 100%;
    }
  }

  .ant-tabs-nav .ant-tabs-nav-list {
    gap: 5px;
  }

  .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-tabs-content-holder {
    height: 100%;
    min-height: 474px;
  }

  .ant-tabs-content {
    padding: 20px;
    margin-bottom: 15px;
  }

  .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
    padding: unset;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

export const ProfileSettingsModalDesktopView = memo(() => {
  const {
    currentView,
    setCurrentView,
    settingsOptions,
    formId,
    contextHolder,
    visibilityHandler,
  } = useProfileSettings('personalInformation');

  const { t } = useTranslation(['user']);

  return (
    <StyledModal
      data-lgg-id="profile-settings-modal"
      visible={visibilityHandler.visible}
      title={
        <Title data-lgg-id="profile-settings-modal-title">
          {t('user:profileSettings.title')}
        </Title>
      }
      centered
      closeIcon={<Icon type="close" />}
      width={650}
      footer={
        <FooterContainer>
          <ButtonV2
            size="small"
            variant="default"
            data-lgg-id="cancel-button"
            form={formId}
            type="reset"
          >
            {t('user:profileSettings.buttons.cancel')}
          </ButtonV2>
          <ButtonV2
            size="small"
            variant="primary"
            data-lgg-id="submit-button"
            form={formId}
            type="submit"
          >
            {t('user:profileSettings.buttons.save')}
          </ButtonV2>
        </FooterContainer>
      }
      onCancel={visibilityHandler.close}
      afterClose={() => setCurrentView('personalInformation')}
    >
      {contextHolder}
      <StyledTabs
        data-lgg-id="profile-settings-tabs"
        activeKey={currentView}
        onChange={(activeKey) => setCurrentView(activeKey as SettingsOptionsKeys)}
        tabPosition="left"
      >
        {Object.entries(settingsOptions).map(([key, { icon, title, content }]) => (
          <Tabs.TabPane
            key={key}
            tab={
              <MenuItem
                key={key}
                icon={icon}
                title={title}
                isActive={currentView === key}
              />
            }
          >
            {content}
          </Tabs.TabPane>
        ))}
      </StyledTabs>
    </StyledModal>
  );
});
