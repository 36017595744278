import React, { memo, MouseEventHandler, useCallback } from 'react';
import Dropdown, { DropDownProps } from 'antd/lib/dropdown';
import c from 'classnames';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import loadingAnimation from 'src/assets/animations/select_spinner.json';
import { ButtonSize, ButtonVariant } from 'src/components/general/button/shared';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { AdaptiveOptionItem } from 'src/components/general/items/adaptive-option-item';
import { FlexRow } from 'src/components/layout/flex-row';
import { useVisible, VisibilityHandler } from 'src/hooks/use-visible';
import { Icon } from '../icon';
import { ButtonV2 } from './lgg-button';

export const DropdownButton = styled(ButtonV2)`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.font.medium};
  white-space: nowrap;

  & > span {
    align-items: center;
    display: flex;
  }
`;

export const DropdownButtonIcon = styled(Icon)`
  align-items: center;
  display: flex;
  height: 10px;
  margin-left: 10px;
  transform: rotate(0) !important;
  transition: all 0.3s ease !important;
  width: 10px;

  &.active {
    transform: rotate(180deg) !important;
    transition: all 0.3s ease !important;
  }

  svg {
    width: 12px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

type BaseDropdownProps = {
  customDropdownProps?: Partial<DropDownProps>;
  overlay: React.ReactElement;
  overlayClassName?: string;
  visibilityHandler: VisibilityHandler;
  loading?: boolean;
};

export const LggBaseDropdown = memo<React.PropsWithChildren<BaseDropdownProps>>(
  ({
    customDropdownProps,
    visibilityHandler,
    loading,
    overlay,
    children,
    overlayClassName,
  }) => {
    const dropdownProps: Partial<DropDownProps> = {
      placement: 'bottomLeft',
      align: {
        offset: [-1, 3],
      },
      ...customDropdownProps,
    };

    if (loading) {
      return <>{children}</>;
    }

    return (
      <Dropdown
        {...dropdownProps}
        trigger={['click']}
        overlayClassName={`context-menu-v2  ${overlayClassName ? overlayClassName : ''}`}
        visible={visibilityHandler.visible}
        onVisibleChange={visibilityHandler.setVisible}
        overlay={overlay}
      >
        {children}
      </Dropdown>
    );
  },
);

const ItemsContainer = styled.div`
  padding: 5px 0;
`;

type ItemsRendererProps = {
  onClose: VoidFunction;
  options: Array<DrawerOption | DrawerSelectableOption>;
  selectedValue?: string | null;
  testId?: string;
};

const ItemsRenderer = memo<ItemsRendererProps>(
  ({ options, selectedValue, onClose, testId }) => {
    const handleOnClick = useCallback(
      (onClick: VoidFunction) => {
        onClick();
        onClose();
      },
      [onClose],
    );

    return (
      <ItemsContainer data-lgg-id={testId}>
        {options.map(({ label, icon, onClick, ...rest }) => {
          if ('value' in rest) {
            // Dropdown input items use case
            return (
              <AdaptiveOptionItem
                key={label}
                label={label}
                icon={icon}
                selected={selectedValue === rest.value}
                onClick={(e) => handleOnClick(() => onClick?.(e))}
                {...rest}
              />
            );
          }

          // Dropdown menu redirect links
          return (
            <AdaptiveOptionItem
              key={label}
              label={label}
              icon={icon}
              onClick={(e) => handleOnClick(() => onClick?.(e))}
              {...rest}
            />
          );
        })}
      </ItemsContainer>
    );
  },
);

const LoadingAnimation = styled(Lottie)`
  height: 18px;
  width: 18px;
  margin-right: 10px;

  svg path {
    stroke: ${({ theme }) => theme.colors.flint};
  }
`;

const LggDropdownButtonWithoutOverlayContent = styled(FlexRow)`
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const LggDropdownButtonWithoutOverlayLabel = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

type LggDropdownButtonWithoutOverlayProps = {
  icon?: string;
  isActive: boolean;
  onClick?: MouseEventHandler;
  size: ButtonSize;
  loading?: boolean;
  variant: Extract<ButtonVariant, 'default' | 'defaultGhost' | 'defaultWhite' | 'cta'>;
  className?: string;
};

export const LggDropdownButtonWithoutOverlay = memo<
  React.PropsWithChildren<LggDropdownButtonWithoutOverlayProps>
>(({ isActive, loading, children, ...rest }) => {
  return (
    <DropdownButton
      {...rest}
      className={`${rest.className ? rest.className : ''} ${c({ loading })}`}
      icon={
        loading ? (
          <LoadingAnimation animationData={loadingAnimation} loop={true} />
        ) : (
          rest.icon
        )
      }
    >
      <LggDropdownButtonWithoutOverlayContent>
        <LggDropdownButtonWithoutOverlayLabel className="dropdown-label">
          {children}
        </LggDropdownButtonWithoutOverlayLabel>
        <DropdownButtonIcon type="arrowdown" className={c({ active: isActive })} />
      </LggDropdownButtonWithoutOverlayContent>
    </DropdownButton>
  );
});

type LggDropdownButtonProps = {
  customDropdownProps?: Partial<DropDownProps>;
  icon?: string;
  overlay: React.ReactElement;
  overlayClassName?: string;
  size: ButtonSize;
  variant: Extract<ButtonVariant, 'default' | 'defaultGhost' | 'defaultWhite' | 'cta'>;
  visibilityHandler: VisibilityHandler;
};

export const LggDropdownButton = memo<React.PropsWithChildren<LggDropdownButtonProps>>(
  ({
    children,
    overlayClassName,
    overlay,
    visibilityHandler,
    customDropdownProps,
    ...rest
  }) => {
    return (
      <LggBaseDropdown
        overlayClassName={overlayClassName}
        visibilityHandler={visibilityHandler}
        customDropdownProps={customDropdownProps}
        overlay={overlay}
      >
        <LggDropdownButtonWithoutOverlay isActive={visibilityHandler.visible} {...rest}>
          {children}
        </LggDropdownButtonWithoutOverlay>
      </LggBaseDropdown>
    );
  },
);

export type LggSelectableOptionsDropdownButtonProps = {
  customDropdownProps?: Partial<DropDownProps>;
  icon?: string;
  loading?: boolean;
  onClick?: MouseEventHandler;
  options: DrawerSelectableOption[];
  selectedValue: string | null;
  size: ButtonSize;
  variant: Extract<ButtonVariant, 'default' | 'defaultGhost' | 'defaultWhite'>;
  visibilityHandler: VisibilityHandler;
};

export const LggSelectableOptionsDropdownButton = memo<
  React.PropsWithChildren<LggSelectableOptionsDropdownButtonProps>
>(
  ({
    children,
    selectedValue,
    options,
    visibilityHandler,
    customDropdownProps,
    ...rest
  }) => {
    return (
      <LggBaseDropdown
        visibilityHandler={visibilityHandler}
        customDropdownProps={customDropdownProps}
        overlayClassName={customDropdownProps?.overlayClassName}
        overlay={
          <ItemsRenderer
            options={options}
            selectedValue={selectedValue}
            onClose={visibilityHandler.close}
          />
        }
      >
        <LggDropdownButtonWithoutOverlay isActive={visibilityHandler.visible} {...rest}>
          {children}
        </LggDropdownButtonWithoutOverlay>
      </LggBaseDropdown>
    );
  },
);

export const LggSelectableOptionsDropdownButtonWithCustomTrigger = memo<
  React.PropsWithChildren<LggSelectableOptionsDropdownButtonProps> & {
    overlayClassName?: string;
  }
>(
  ({
    children,
    selectedValue,
    options,
    visibilityHandler,
    customDropdownProps,
    overlayClassName = '',
    ...rest
  }) => {
    return (
      <LggBaseDropdown
        overlayClassName={overlayClassName}
        visibilityHandler={visibilityHandler}
        customDropdownProps={customDropdownProps}
        overlay={
          <ItemsRenderer
            options={options}
            selectedValue={selectedValue}
            onClose={visibilityHandler.close}
          />
        }
        {...rest}
      >
        {children}
      </LggBaseDropdown>
    );
  },
);

type LggOptionsDropdownButtonProps = Omit<
  LggSelectableOptionsDropdownButtonProps,
  'selectedValue' | 'options'
> & {
  options: DrawerOption[];
};

export const LggOptionsDropdownButton = memo<
  React.PropsWithChildren<LggOptionsDropdownButtonProps>
>(({ children, options, visibilityHandler, customDropdownProps, ...rest }) => {
  return (
    <LggBaseDropdown
      customDropdownProps={customDropdownProps}
      visibilityHandler={visibilityHandler}
      loading={rest.loading}
      overlay={<ItemsRenderer options={options} onClose={visibilityHandler.close} />}
    >
      <LggDropdownButtonWithoutOverlay isActive={visibilityHandler.visible} {...rest}>
        {children}
      </LggDropdownButtonWithoutOverlay>
    </LggBaseDropdown>
  );
});

type LggOptionsDropdownButtonWithCustomTriggerProps = LggOptionsDropdownButtonProps & {
  customTrigger: React.ReactNode;
  overlayTestId?: string;
};

export const LggOptionsDropdownButtonWithCustomTrigger = memo<
  Omit<LggOptionsDropdownButtonWithCustomTriggerProps, 'size' | 'variant'>
>(
  ({
    options,
    visibilityHandler,
    customDropdownProps,
    customTrigger,
    loading,
    overlayTestId,
  }) => {
    return (
      <LggBaseDropdown
        customDropdownProps={customDropdownProps}
        visibilityHandler={visibilityHandler}
        overlay={
          <ItemsRenderer
            options={options}
            onClose={visibilityHandler.close}
            testId={overlayTestId}
          />
        }
        loading={loading}
      >
        {customTrigger}
      </LggBaseDropdown>
    );
  },
);

export const LggOptionsDropdownButtonWithCustomTriggerControlled = memo<
  Omit<
    LggOptionsDropdownButtonWithCustomTriggerProps,
    'size' | 'variant' | 'visibilityHandler'
  >
>(({ options, customDropdownProps, customTrigger }) => {
  const visibilityHandler = useVisible();

  return (
    <LggBaseDropdown
      customDropdownProps={customDropdownProps}
      visibilityHandler={visibilityHandler}
      overlay={<ItemsRenderer options={options} onClose={visibilityHandler.close} />}
    >
      {customTrigger}
    </LggBaseDropdown>
  );
});
