import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import styled, { useTheme, ThemeProvider } from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const ConfirmationModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 100%
  height: 100%;
  height: 207px;
  max-width: 416px;
  border-radius: 6px;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.1);
  pointer-events: all;
`;

const ConfirmationModalTitle = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.48px;
  color: ${({ theme }) => theme.colors.carbon};
  margin: 0 0 10px;
  padding-right: 26px;
  text-transform: capitalize;
`;

const ConfirmationModalMessage = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.smalt};
  margin: 0;
  padding-right: 16px;
`;

const ConfirmationModalBody = styled.div`
  padding: 20px;
`;

const ConfirmationModalFooter = styled(FlexRow)`
  padding: 15px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 15px;
  }
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const DialogButton = styled(ButtonV2)`
  text-transform: capitalize;
`;

type ShowConfirmationModalProps = {
  title: string;
  message: string;
  testId: string;
  onConfirm: VoidFunction;
  confirmButtonText: string;
  onCancel?: VoidFunction;
};

export const useShowConfirmationModal = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const ShowConfirmationModal = useCallback(
    ({
      title,
      message,
      onConfirm,
      onCancel,
      confirmButtonText,
      testId,
    }: ShowConfirmationModalProps) => {
      const confirmationModal = Modal.confirm({
        visible: false,
        modalRender: () => <></>,
      });

      const closeConfirmationModal = () => {
        confirmationModal.destroy();
      };

      confirmationModal.update({
        maskClosable: true,
        visible: true,
        icon: null,
        closable: true,
        zIndex: ModalsZIndex,
        onCancel: closeConfirmationModal,
        modalRender: (_) => {
          return (
            <ThemeProvider theme={theme}>
              <ConfirmationModalContent data-lgg-id={testId}>
                <CloseIcon type="close" onClick={closeConfirmationModal} />
                <ConfirmationModalBody>
                  <ConfirmationModalTitle>{title}</ConfirmationModalTitle>
                  <ConfirmationModalMessage>{message}</ConfirmationModalMessage>
                </ConfirmationModalBody>
                <ConfirmationModalFooter>
                  <DialogButton
                    data-lgg-id="confirmation-modal-cancel-button"
                    variant="default"
                    size="small"
                    onClick={() => {
                      onCancel?.();
                      closeConfirmationModal();
                    }}
                  >
                    {t('common:cancel')}
                  </DialogButton>
                  <DialogButton
                    data-lgg-id="confirmation-modal-confirm-button"
                    variant="cta"
                    size="small"
                    onClick={() => {
                      onConfirm();
                      closeConfirmationModal();
                    }}
                  >
                    {confirmButtonText}
                  </DialogButton>
                </ConfirmationModalFooter>
              </ConfirmationModalContent>
            </ThemeProvider>
          );
        },
      });
    },
    [t, theme],
  );

  return ShowConfirmationModal;
};
