import React, { memo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  ContactInteractionChannelSlug,
  ContactInteractionChannel,
  Mutation,
  Query,
  MutationConversationValidateAttachmentsArgs,
  ConversationValidateFileAttachmentInput,
  MutationConversationGenerateS3PresignedPostArgs,
  ContactInteractionAttachmentFileType,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useVisible } from 'src/hooks/use-visible';
import { compressImage } from 'src/utils/compress-image';
import { AttachmentPreviewModal } from '../../general/attachment-preview-modal';
import { InputUploadFile } from './contact-interaction-input-area-attachment-options';

const GET_CONTACT_INTERACTION_CHANNELS = gql`
  query ContactInteractionChannels {
    contactInteractionChannels {
      slug
      fileAttachmentConstraints {
        maxIndividualFileSizeBytes
        totalSizeBytes
        maxFiles
        allowedTypes {
          attachmentType
          mimeTypes
        }
      }
    }
  }
`;

const VALIDATE_FILE_ATTACHMENTS = gql`
  mutation ConversationValidateAttachments(
    $channel: ContactInteractionChannelSlug!
    $attachments: [ConversationValidateFileAttachmentInput!]!
  ) {
    conversationValidateAttachments(channel: $channel, attachments: $attachments) {
      ... on HasError {
        hasError
      }
      ... on ConversationValidateAttachmentsError {
        noChannelConfig
        maxFilesExceeded
        totalSizeExceeded
        fileErrors {
          ref
          individualFileSizeExceeded
          attachmentTypeNotAllowed
          mimeTypeNotAllowed
        }
      }
    }
  }
`;

const GENERATE_S3_PRESIGNED_POST = gql`
  mutation ConversationGenerateS3PresignedPost(
    $channel: ContactInteractionChannelSlug!
    $attachment: ConversationValidateFileAttachmentInput!
  ) {
    conversationGenerateS3PresignedPost(channel: $channel, attachment: $attachment) {
      url
      fields {
        key
        value
      }
    }
  }
`;

const StyledScrollbar = styled(Scrollbar)`
  border-top: 1px solid rgba(233, 238, 242, 0.5);
  border-bottom: 1px solid rgba(233, 238, 242, 0.5);
  padding: 4px 20px 5px 20px;
  margin-bottom: 9px;
  overflow-y: hidden !important;

  ${up('md')} {
    overflow: visible !important;
    padding: 4px 20px 0 20px;
  }
`;

const MessageAttachmentsList = styled(FlexRow)`
  flex-wrap: nowrap;
  height: max-content;
  width: max-content;

  &:empty {
    display: none;
  }

  ${up('md')} {
    flex-wrap: wrap;
    width: unset;
  }
`;

const MessageAttachmentContainer = styled(FlexRow)`
  width: max-content;
  max-width: 219px;
  height: 28px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 4px;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;

  ${up('md')} {
    margin-bottom: 4px;
  }

  &.with-preview {
    cursor: pointer;
  }
`;

const MessageAttachmentTypeIcon = styled(Icon)`
  margin-right: 6px;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const MessageAttachmentRemoveIcon = styled(Icon)`
  margin-left: 10px;
  z-index: 2;

  svg {
    width: 10px;
    height: 10px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const MessageAttachmentFileName = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.storm};
  margin: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 2;
`;

const MessageAttachmentUploadProgress = styled.span<{ progress: number }>`
  position: absolute;
  height: 100%;
  width: ${({ progress }) => progress}%;
  top: 0;
  left: 0;
  background-color: rgba(45, 152, 218, 0.15);
  z-index: 1;
`;

const RetryActionNotificationOption = styled.span`
  margin: 0;
  color: ${({ theme }) => theme.colors.secondaryCoral};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  cursor: pointer;
`;

type PresignedPost = {
  url: string;
  fields: { key: string; value: string }[];
};

type MessageAttachmentProps = {
  attachment: InputUploadFile;
  attachmentList: InputUploadFile[];
  onRemove: VoidFunction;
  onStatusChange: (status: InputUploadFile['status']) => void;
  contactInteractionChannel: ContactInteractionChannel | undefined;
  onUploadCompleted: (
    id: string,
    url: string,
    attachmentType: ContactInteractionAttachmentFileType,
  ) => void;
};

const MessageAttachment = ({
  attachment,
  onRemove,
  onUploadCompleted,
  contactInteractionChannel,
  attachmentList,
  onStatusChange,
}: MessageAttachmentProps) => {
  const { t } = useTranslation(['conversations', 'common']);
  const [progress, setProgress] = useState<number>(
    attachment.status === 'UPLOADED' || attachment.isRecentFile ? 100 : 0,
  );
  const [shouldUploadFile, setShouldUploadFile] = useState<boolean>(true);
  const { visible: isPreviewVisible, setVisible: setPreviewVisible } = useVisible();
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();
  const [validateFileAttachments] = useMutation<
    Pick<Mutation, 'conversationValidateAttachments'>,
    MutationConversationValidateAttachmentsArgs
  >(VALIDATE_FILE_ATTACHMENTS, { onError: handleGraphQLError });
  const [generateS3PresignedPost] = useMutation<
    Pick<Mutation, 'conversationGenerateS3PresignedPost'>,
    MutationConversationGenerateS3PresignedPostArgs
  >(GENERATE_S3_PRESIGNED_POST, {
    onError: handleGraphQLError,
  });

  const selectedChannelConstraints = contactInteractionChannel?.fileAttachmentConstraints;
  const selectedChannelConstraintsAllowedTypes =
    contactInteractionChannel?.fileAttachmentConstraints?.allowedTypes;
  const attachmentType = selectedChannelConstraints?.allowedTypes?.find((value) =>
    value.mimeTypes.includes(attachment.file.type),
  )?.attachmentType;

  const uploadFileToS3 = async (
    file: File,
    presignedPost: PresignedPost,
    onUploadProgress: (progress: number) => void,
  ) => {
    const formData = new FormData();
    formData.append('Content-Type', file.type);

    for (const { key, value } of presignedPost.fields) {
      formData.append(key, value);
    }

    formData.append('file', file);

    const { headers } = await axios.post(presignedPost.url, formData, {
      onUploadProgress: (e) => {
        const progress = (100 * e.loaded) / e.total;

        onUploadProgress(progress);
      },
    });

    const location = headers['location'];

    return location ? decodeURIComponent(location) : null;
  };

  const resolveAttachmentIcon = (type?: ContactInteractionAttachmentFileType) => {
    switch (type) {
      case 'AUDIO': {
        return 'attachedAudio';
      }
      case 'IMAGE': {
        return 'attachedImage';
      }
      case 'VIDEO': {
        return 'attachedVideo';
      }
      default:
        return 'attachedFile';
    }
  };

  const convertBytesToMegaBytes = (bytes: number) => bytes / 1000000;

  useEffect(() => {
    const fileUploadHandler = async (inputUploadFile: InputUploadFile) => {
      const ref = uuid();
      const isRecentFile = inputUploadFile.isRecentFile;
      const maxIndividualFileSizeBytes =
        selectedChannelConstraints?.maxIndividualFileSizeBytes;
      const channelMaxIndividualFileSizeMb = maxIndividualFileSizeBytes
        ? convertBytesToMegaBytes(maxIndividualFileSizeBytes)
        : undefined;
      const inputFileType = inputUploadFile.file.type;
      const effectiveUploadFile =
        !isRecentFile && inputFileType.startsWith('image/')
          ? {
              ...inputUploadFile,
              file: await compressImage(
                inputUploadFile.file,
                channelMaxIndividualFileSizeMb,
              ),
            }
          : inputUploadFile;

      const {
        name: filename,
        size: sizeBytes,
        type: mimeType,
      } = effectiveUploadFile.file;

      const OnUploadFail = (removeFromList: boolean = true) => {
        if (removeFromList) {
          onRemove();
        } else {
          onStatusChange('ERROR');
        }
      };

      const handleDefaultError = () => {
        showNotification({
          title: t('conversations:messageInput.options.attachments.errorMessages.error'),
          message: (
            <RetryActionNotificationOption
              onClick={() => {
                setShouldUploadFile(true);
              }}
            >
              {t('common:actions.tryAgain')}
            </RetryActionNotificationOption>
          ),
          type: 'error',
        });

        OnUploadFail(false);
      };

      const showFileNotAllowedNotification = () => {
        const fileExtension = effectiveUploadFile.file.name.split('.').pop();

        showNotification({
          title: effectiveUploadFile.file.name,
          message: t(
            'conversations:messageInput.options.attachments.errorMessages.unsupportedFormat',
            {
              extension: fileExtension,
            },
          ),
          type: 'warning',
        });
      };

      if (!attachmentType || !mimeType || !sizeBytes) {
        if (!attachmentType) {
          showFileNotAllowedNotification();
          OnUploadFail();
        } else {
          handleDefaultError();
        }

        return;
      }

      const baseFile = {
        ref,
        filename,
        attachmentType,
        mimeType,
        sizeBytes,
      };

      if (!contactInteractionChannel?.slug) {
        return;
      }

      const attachmentsWithType = attachmentList
        .filter((a) => a.attachmentType !== null)
        .map(({ attachmentType, id, file }) => ({
          ref: id,
          filename: file.name,
          attachmentType,
          mimeType: file.type,
          sizeBytes: file.size,
        })) as ConversationValidateFileAttachmentInput[];

      const { data: validateFileAttachmentsData } = await validateFileAttachments({
        variables: {
          channel: contactInteractionChannel?.slug,
          attachments: [baseFile, ...attachmentsWithType],
        },
      });

      if (
        validateFileAttachmentsData?.conversationValidateAttachments.__typename ===
        'ConversationValidateAttachmentsError'
      ) {
        const { maxFilesExceeded, fileErrors } =
          validateFileAttachmentsData?.conversationValidateAttachments;
        const currentFileErrors = fileErrors?.find((fileError) => fileError.ref === ref);

        if (maxFilesExceeded) {
          showNotification({
            title: t(
              'conversations:messageInput.options.attachments.errorMessages.filesCountLimit',
              {
                filesCountLimit: selectedChannelConstraints?.maxFiles,
              },
            ),
            type: 'warning',
          });
        }

        if (currentFileErrors?.individualFileSizeExceeded) {
          const fileSize = convertBytesToMegaBytes(effectiveUploadFile.file.size);

          showNotification({
            title: `${effectiveUploadFile.file.name} (${fileSize.toFixed(2)} MB)`,
            message: t(
              'conversations:messageInput.options.attachments.errorMessages.maximumFilesSize',
              {
                sizeLimit: convertBytesToMegaBytes(
                  selectedChannelConstraints?.maxIndividualFileSizeBytes ?? 0,
                ),
              },
            ),
            type: 'warning',
          });
        }

        if (currentFileErrors?.attachmentTypeNotAllowed) {
          showFileNotAllowedNotification();
        }

        OnUploadFail();
        return;
      }

      if (isRecentFile) {
        if (attachment.url) {
          onUploadCompleted(effectiveUploadFile.id, attachment.url, attachmentType);
        } else {
          handleDefaultError();

          return;
        }
      } else {
        const {
          data: generateS3PresignedPostData,
          errors: generateS3PresignedPostErrors,
        } = await generateS3PresignedPost({
          variables: {
            channel: contactInteractionChannel?.slug,
            attachment: baseFile,
          },
        });

        if (generateS3PresignedPostErrors) {
          handleDefaultError();

          return;
        }

        if (generateS3PresignedPostData) {
          try {
            onStatusChange('UPLOADING');

            const attachmentUrl = await uploadFileToS3(
              effectiveUploadFile.file,
              generateS3PresignedPostData.conversationGenerateS3PresignedPost,
              (progress) => {
                setProgress(progress);
              },
            );

            if (attachmentUrl) {
              onUploadCompleted(effectiveUploadFile.id, attachmentUrl, attachmentType);
            }
          } catch (e) {
            handleDefaultError();

            return;
          }
        }
      }
    };

    if (
      shouldUploadFile &&
      contactInteractionChannel &&
      attachment.status === 'VALIDATING'
    ) {
      void fileUploadHandler(attachment);

      setShouldUploadFile(false);
    }
  }, [
    attachment,
    generateS3PresignedPost,
    selectedChannelConstraintsAllowedTypes,
    validateFileAttachments,
    selectedChannelConstraints,
    showNotification,
    t,
    onUploadCompleted,
    shouldUploadFile,
    attachmentList,
    onStatusChange,
    onRemove,
    contactInteractionChannel?.slug,
    attachmentType,
    contactInteractionChannel,
  ]);

  const {
    url,
    file: { name, type: mimeType },
  } = attachment;

  const hasPreview = url && attachmentType;
  const shouldRender = !['ERROR', 'VALIDATING'].includes(attachment.status);

  return shouldRender ? (
    <>
      <MessageAttachmentContainer
        data-lgg-id="contact-interaction-message-attachment-item"
        data-status={attachment.status}
        className={c({ 'with-preview': hasPreview })}
        onClick={() => {
          if (hasPreview) {
            setPreviewVisible(true);
          }
        }}
      >
        <MessageAttachmentUploadProgress progress={progress} />
        <MessageAttachmentTypeIcon type={resolveAttachmentIcon(attachmentType)} />
        <MessageAttachmentFileName>{name}</MessageAttachmentFileName>
        <MessageAttachmentRemoveIcon
          type="close"
          lggTestId="contact-interaction-message-attachment-item-remove-icon"
          onClick={(e) => {
            e?.stopPropagation();
            onRemove();
          }}
        />
      </MessageAttachmentContainer>
      {hasPreview && (
        <AttachmentPreviewModal
          type={attachmentType}
          url={url}
          name={name}
          visible={isPreviewVisible}
          mimeType={mimeType}
          onClose={() => {
            setPreviewVisible(false);
          }}
          testId="input-message-attachment-preview"
        />
      )}
    </>
  ) : (
    <></>
  );
};

type MessageAttachmentsProps = {
  attachments: InputUploadFile[];
  selectedChannel: ContactInteractionChannelSlug;
  setAttachmentList: Dispatch<SetStateAction<InputUploadFile[]>>;
};

export const MessageAttachments = memo(
  ({ attachments, setAttachmentList, selectedChannel }: MessageAttachmentsProps) => {
    const handleGraphQLError = useHandleGraphQLError();
    const {
      data: contactInteractionChannels,
      loading: loadingContactInteractionChannels,
    } = useQuery<Pick<Query, 'contactInteractionChannels'>>(
      GET_CONTACT_INTERACTION_CHANNELS,
      {
        onError: handleGraphQLError,
      },
    );

    const contactInteractionChannel =
      contactInteractionChannels?.contactInteractionChannels.find(
        (value) => value.slug === selectedChannel,
      );

    return (
      <StyledScrollbar>
        <MessageAttachmentsList data-lgg-id="contact-interaction-message-attachment-list">
          {attachments.map((attachment) => (
            <MessageAttachment
              key={attachment.id}
              attachment={attachment}
              attachmentList={attachments}
              contactInteractionChannel={
                contactInteractionChannel !== undefined &&
                !loadingContactInteractionChannels
                  ? contactInteractionChannel
                  : undefined
              }
              onRemove={() => {
                setAttachmentList((attachments) => {
                  return attachments.filter(
                    (attachmentListItem) => attachmentListItem.id !== attachment.id,
                  );
                });
              }}
              onStatusChange={(status: InputUploadFile['status']) => {
                setAttachmentList((attachmentList) => {
                  return [...attachmentList].map((attachmentListItem) => {
                    if (attachmentListItem.id === attachment.id) {
                      attachmentListItem = {
                        ...attachmentListItem,
                        status,
                      };
                    }

                    return attachmentListItem;
                  });
                });
              }}
              onUploadCompleted={(
                id: string,
                url: string,
                attachmentType: ContactInteractionAttachmentFileType,
              ) => {
                setAttachmentList((attachmentList) => {
                  return [...attachmentList].map((attachmentListItem) => {
                    if (id === attachmentListItem.id) {
                      attachmentListItem = {
                        ...attachmentListItem,
                        url,
                        attachmentType,
                        status: 'UPLOADED',
                      };
                    }

                    return attachmentListItem;
                  });
                });
              }}
            />
          ))}
        </MessageAttachmentsList>
      </StyledScrollbar>
    );
  },
);
