import React, { createContext, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import { Modal } from 'antd';
import { LegacyButtonType } from 'antd/lib/button/button';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { useGetUser } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-get-user';
import { Icon } from 'src/components/general/icon';
import { useVisible, VisibilityHandler } from 'src/hooks/use-visible';
import { SessionService } from 'src/services/session.service';

interface ProfileSettingsContextProps {
  visibilityHandler: VisibilityHandler;
  currentUser?: User;
  contextHolder: React.ReactElement;
  showCancelConfirm: (isDirty: boolean, reset: () => void) => void;
}

export const ProfileSettingsContext = createContext<
  ProfileSettingsContextProps | undefined
>(undefined);

export const ProfileSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const visibilityHandler = useVisible();

  const userId = useServiceSelector(SessionService, ({ data: { user } }) => user.id);

  const currentUser = useGetUser(userId);

  const { t } = useTranslation(['user']);

  const showCancelConfirm = useCallback(
    (isDirty: boolean, reset: () => void) => {
      if (!isDirty) {
        visibilityHandler.close();
        return;
      }

      const config = {
        className: 'confirm-modal',
        title: t('user:profileSettings.confirmModal.title'),
        icon: null,
        content: <p>{t('user:profileSettings.confirmModal.content')}</p>,
        zIndex: 1099,
        width: 420,
        centered: true,
        closable: true,
        closeIcon: <Icon type="close" />,
        okText: t('user:profileSettings.confirmModal.buttons.ok'),
        okType: 'danger' as LegacyButtonType,
        cancelText: t('user:profileSettings.confirmModal.buttons.cancel'),
        onOk: () => {
          reset();
          visibilityHandler.close();
        },
      };

      modal.confirm(config);
    },
    [t, modal, visibilityHandler],
  );

  return (
    <ProfileSettingsContext.Provider
      value={{ visibilityHandler, currentUser, showCancelConfirm, contextHolder }}
    >
      {children}
    </ProfileSettingsContext.Provider>
  );
};
