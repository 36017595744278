import React, { memo } from 'react';
import styled from 'styled-components';
import {
  User,
  ConversationItemUnion,
  ContactInteractionInPerson,
  ContactInteractionSms,
  ContactInteractionPhoneCall,
  ContactInteractionEmail,
  ContactInteractionFacebookMessenger,
  ContactInteractionUnknown,
  ContactInteractionWhatsapp,
  ContactInteractionDirection,
  ContactInteractionInstagram,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

const StyledMessageGroupLabel = styled(FlexRow)`
  padding-top: 15px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubjectLabel = styled.span<{ direction: ContactInteractionDirection }>`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.smalt};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  ${({ direction }) => {
    if (direction === 'OUTBOUND') {
      return `
        margin-right: 5px;
        margin-left: auto;
      `;
    } else {
      return `
        margin-left: 5px;
        margin-right: auto;
      `;
    }
  }}
`;

const ChannelIcon = styled(Icon)`
  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

type ContactInteractionWithParticipatingUser = (
  | ContactInteractionSms
  | ContactInteractionPhoneCall
  | ContactInteractionEmail
  | ContactInteractionFacebookMessenger
  | ContactInteractionInPerson
) & {
  participatingUser: User;
};

export const isOutboundInteraction = (
  interaction:
    | ContactInteractionInPerson
    | ContactInteractionSms
    | ContactInteractionPhoneCall
    | ContactInteractionEmail
    | ContactInteractionUnknown
    | ContactInteractionWhatsapp // TODO
    | ContactInteractionFacebookMessenger
    | ContactInteractionInstagram,
): interaction is ContactInteractionWithParticipatingUser => {
  return (
    interaction.__typename === 'ContactInteractionInPerson' ||
    interaction.direction === 'OUTBOUND'
  );
};

type MessageGroupLabelProps = {
  contactInteraction: ConversationItemUnion;
  contactLabel: string;
};

export const MessageGroupLabel = memo<MessageGroupLabelProps>(
  ({ contactInteraction, contactLabel }) => {
    const interactionType = contactInteraction.__typename;
    const direction =
      'direction' in contactInteraction
        ? contactInteraction.direction
        : interactionType === 'ContactInteractionFacebookAd'
        ? 'INBOUND'
        : 'OUTBOUND';
    const isOutbound = direction === 'OUTBOUND';
    let icon: string | null = null;
    let subject: string;

    if (interactionType === 'ConversationNote') {
      subject = contactInteraction.createdBy.fullName;
    } else if (interactionType === 'ContactInteractionFacebookAd') {
      subject = contactLabel;
    } else if (isOutboundInteraction(contactInteraction)) {
      subject = contactInteraction.participatingUser.fullName;
    } else {
      subject = contactLabel;
    }

    switch (interactionType) {
      case 'ContactInteractionInPerson':
        icon = 'inPerson';
        break;
      case 'ContactInteractionFacebookMessenger':
        icon = 'facebookMessenger';
        break;
      case 'ContactInteractionFacebookAd':
        icon = 'facebook';
        break;
      case 'ContactInteractionEmail':
        icon = 'email';
        break;
      case 'ContactInteractionSms':
        icon = 'sms';
        break;
      case 'ContactInteractionPhoneCall':
        icon = 'phone';
        break;
      case 'ContactInteractionWhatsapp':
        icon = 'whatsapp';
        break;
      case 'ContactInteractionInstagram':
        icon = 'instagramAlt';
        break;
      case 'ConversationNote':
        icon = 'note';
        break;
    }

    const iconElement = icon && (
      <ChannelIcon type={icon} lggTestId={`contact-interaction-group-icon-${icon}`} />
    );

    return (
      <StyledMessageGroupLabel
        data-lgg-id={`contact-interaction-message-group-label-${interactionType}`}
      >
        {!isOutbound && iconElement}
        <SubjectLabel direction={direction}>{subject}</SubjectLabel>
        {isOutbound && iconElement}
      </StyledMessageGroupLabel>
    );
  },
);
