import errors from 'src/i18n/locales/en/errors.json';
import account from 'src/i18n/locales/es/account.json';
import activity from 'src/i18n/locales/es/activity.json';
import appointments from 'src/i18n/locales/es/appointments.json';
import calendar from 'src/i18n/locales/es/calendar.json';
import common from 'src/i18n/locales/es/common.json';
import contactTimeline from 'src/i18n/locales/es/contact-timeline.json';
import contacts from 'src/i18n/locales/es/contacts.json';
import conversations from 'src/i18n/locales/es/conversations.json';
import header from 'src/i18n/locales/es/header.json';
import instagramChannel from 'src/i18n/locales/es/instagram-channel.json';
import notes from 'src/i18n/locales/es/notes.json';
import notifications from 'src/i18n/locales/es/notifications.json';
import tasks from 'src/i18n/locales/es/tasks.json';
import user from 'src/i18n/locales/es/user.json';
import whatsappChannel from 'src/i18n/locales/es/whatsapp-channel.json';

export const es = {
  account,
  activity,
  appointments,
  calendar,
  common,
  contacts,
  conversations,
  contactTimeline,
  errors,
  header,
  notes,
  notifications,
  tasks,
  user,
  whatsappChannel,
  instagramChannel,
};
