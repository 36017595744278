/* eslint-disable no-console */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationActivateInstagramResourceArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { ACTIVATE_INSTAGRAM_RESOURCE_MUTATION } from 'src/components/pages/instagram-channel/shared/queries';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';
import { openPopup } from 'src/utils/open-popup';
import { parseQueryParams } from 'src/utils/parse-query-params';

export const useActivateInstagramResource = () => {
  return useMutation<
    Pick<Mutation, 'activateInstagramResource'>,
    MutationActivateInstagramResourceArgs
  >(ACTIVATE_INSTAGRAM_RESOURCE_MUTATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};

export const useInstagramResourceActivation = () => {
  const { t } = useTranslation(['instagramChannel']);
  const { id: companyId } = useCurrentInstitution();

  const showNotification = useShowNotification();

  const [activateInstagramResource, { loading: isActivatingResource }] =
    useActivateInstagramResource();

  const handleActivateInstagramResource = useCallback(
    (code: string) => {
      showNotification({
        title: t('instagramChannel:activateResource.warning.title'),
        message: t('instagramChannel:activateResource.warning.message'),
        type: 'warning',
      });

      void activateInstagramResource({
        variables: { code, companyId },
        onCompleted: async () => {
          showNotification({
            title: t('instagramChannel:activateResource.success.title'),
            message: t('instagramChannel:activateResource.success.message'),
            type: 'success',
          });
        },
        onError: () => {
          showNotification({
            title: t('instagramChannel:activateResource.error.title'),
            message: t('instagramChannel:activateResource.error.general'),
            type: 'error',
          });
        },
      });
    },
    [activateInstagramResource, showNotification, t, companyId],
  );

  const institutionUrl = useInstitutionUrl();
  const history = useHistory();

  const checkInstagramAuthentication = useCallback(
    (context: Window) => {
      const { location } = context;

      const queryParams = parseQueryParams(location.search);
      const code = queryParams['code'];

      if (code && typeof code === 'string') {
        history.push(`${institutionUrl}tracking/resources?code=${code}`);
        handleActivateInstagramResource(code);

        return true;
      } else if (location.search.includes('error')) {
        const queryParams = parseQueryParams(location.search);
        const errorText = queryParams['error'];
        const errorReason = queryParams['error_reason'];
        const errorDescription = queryParams['error_description'];

        showNotification({
          title: errorText,
          message: errorReason + ': ' + errorDescription,
          type: 'error',
        });

        return true;
      }

      return false;
    },
    [handleActivateInstagramResource, history, institutionUrl, showNotification],
  );

  const onCredentialsChanged = useCallback(
    (
      popup: Window | null,
      resolve?: (value: Record<string, unknown> | string) => void,
      reject?: () => void,
    ): Promise<Record<string, unknown> | string> | void => {
      const triggerErrorNotification = () => {
        showNotification({
          title: t('instagramChannel:activateResource.error.title'),
          message: t('instagramChannel:activateResource.error.cancelled'),
          type: 'error',
        });
      };

      if (popup == null) {
        triggerErrorNotification();
        return;
      }
      if (!resolve) {
        return new Promise((res, rej) => onCredentialsChanged(popup, res, rej));
      }
      let isFinished;
      try {
        isFinished = checkInstagramAuthentication(popup);
      } catch (err) {
        // An exception is thrown when we try to access to another website's url
      }

      if (isFinished) {
        popup.close();
      } else if (popup.closed) {
        triggerErrorNotification();
      } else {
        setTimeout(() => onCredentialsChanged(popup, resolve, reject), 0);
      }
    },
    [checkInstagramAuthentication, showNotification, t],
  );

  // Function to handle Instagram permission granting workflow
  const launchInstagramPermissionGrant = useCallback(async () => {
    const popup = openPopup({
      width: 600,
      height: 600,
      url: `https://www.instagram.com/oauth/authorize?client_id=${
        import.meta.env.VITE_INSTAGRAM_APP_ID
      }&redirect_uri=${
        window.location.origin
      }/instagram/auth/&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments%2Cbusiness_content_publish`,
      name: 'instagram',
    });
    await onCredentialsChanged(popup);
  }, [onCredentialsChanged]);

  return { launchInstagramPermissionGrant, isActivatingResource };
};
