import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

const MenuItemContainer = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17px 15px;
  cursor: pointer;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.secondaryTopaz10 : theme.colors.porcelain};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.gogo : theme.colors.smalt)};
  border-radius: 4px;
  border: none;
  text-align: left;

  ${up('md')} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.gogo : theme.colors.flint};
    padding: 10px 15px;
    height: 38px;
  }

  &:hover,
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.secondaryTopaz10};
  }

  &:focus {
    outline: none;
  }
`;

const Title = styled.span`
  flex-grow: 1;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  ${up('md')} {
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.regular};
  }
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const RightIconContainer = styled.div`
  margin-left: auto;
  opacity: 0.5;
`;

type MenuItemProps = {
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
  isActive?: boolean;
  rightIcon?: React.ReactNode;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  title,
  onClick,
  isActive = false,
  rightIcon,
}) => {
  return (
    <MenuItemContainer isActive={isActive} onClick={onClick} role="menuitem">
      <IconContainer>{icon}</IconContainer>
      <Title>{title}</Title>
      {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
    </MenuItemContainer>
  );
};
