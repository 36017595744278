import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Mutation,
  MutationUpdateLanguagePreferenceArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  LanguagePreferenceFormValues,
  languageSchema,
} from 'src/components/domain/users/components/profile-settings-modal/form-validation-schemas';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings';
import { UPDATE_LANGUAGE_PREFERENCE_MUTATION } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { useI18n } from 'src/hooks/use-i18n';

export const useLanguagePreferenceForm = () => {
  const { showCancelConfirm, visibilityHandler } = useProfileSettings();

  const [updateLanguagePreference] = useMutation<
    Pick<Mutation, 'updateLanguagePreference'>,
    MutationUpdateLanguagePreferenceArgs
  >(UPDATE_LANGUAGE_PREFERENCE_MUTATION);

  const { currentLanguage, changeLanguage } = useI18n();

  const form = useForm<LanguagePreferenceFormValues>({
    resolver: zodResolver(languageSchema),
    defaultValues: {
      language: currentLanguage,
    },
  });

  const {
    formState: { isDirty },
    reset,
  } = form;

  const showNotification = useShowNotification();

  const { t } = useTranslation(['user']);

  const onSubmit: SubmitHandler<LanguagePreferenceFormValues> = useCallback(
    (data) => {
      void updateLanguagePreference({
        variables: {
          input: data,
        },
        onCompleted: () => {
          visibilityHandler.close();
          showNotification({
            type: 'success',
            title: t('user:profileSettings.sections.languagePreference.toasts.success'),
          });
        },
        onError: () =>
          showNotification({
            type: 'error',
            title: t('user:profileSettings.sections.languagePreference.toasts.error'),
          }),
      });

      void changeLanguage(data?.language);
    },
    [t, updateLanguagePreference, visibilityHandler, changeLanguage, showNotification],
  );

  const onReset = () => showCancelConfirm(isDirty, reset);

  return { form, onReset, onSubmit };
};
