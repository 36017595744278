import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Mutation,
  MutationUpdateUserArgs,
  User,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  PersonalInformationFormValues,
  personalInformationSchema,
} from 'src/components/domain/users/components/profile-settings-modal/form-validation-schemas';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings';
import { UPDATE_USER_MUTATION } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';

const parseDefaultValues = (
  currentUser?: User,
): PersonalInformationFormValues | undefined => {
  if (!currentUser) return undefined;

  const {
    firstName,
    lastName,
    email,
    primaryPhone,
    alternativePhone,
    phoneExt,
    phone2Ext,
  } = currentUser;

  return {
    firstName,
    lastName,
    email,
    phone: primaryPhone?.e164,
    phoneExt,
    phone2: alternativePhone?.e164,
    phone2Ext,
  } as PersonalInformationFormValues;
};

export const usePersonalInformationForm = () => {
  const { currentUser, showCancelConfirm, visibilityHandler } = useProfileSettings();

  const [updateUser] = useMutation<Pick<Mutation, 'updateUser'>, MutationUpdateUserArgs>(
    UPDATE_USER_MUTATION,
  );

  const form = useForm<PersonalInformationFormValues>({
    resolver: zodResolver(personalInformationSchema),
    defaultValues: parseDefaultValues(currentUser),
  });

  const {
    formState: { isDirty },
    reset,
  } = form;

  const showNotification = useShowNotification();

  const { t } = useTranslation(['user']);

  useEffect(() => {
    if (currentUser) {
      reset(parseDefaultValues(currentUser));
    }
  }, [currentUser, reset]);

  const onSubmit: SubmitHandler<PersonalInformationFormValues> = useCallback(
    (data) => {
      void updateUser({
        variables: {
          input: data,
        },
        onCompleted: () => {
          visibilityHandler.close();
          showNotification({
            type: 'success',
            title: t('user:profileSettings.sections.personalInformation.toasts.success'),
          });
        },
        onError: () =>
          showNotification({
            type: 'error',
            title: t('user:profileSettings.sections.personalInformation.toasts.error'),
          }),
      });
    },
    [t, updateUser, visibilityHandler, showNotification],
  );

  const onReset = () => showCancelConfirm(isDirty, reset);

  return {
    form,
    onReset,
    onSubmit,
    currentUser,
  };
};
