import {
  ContactInteractionUnion,
  ConversationItemUnion,
  ConversationNote,
} from '@lgg/isomorphic/types/__generated__/graphql';

const KB_IN_A_BYTE = 0.001;

export const getDirectionFromContactInteraction = (
  contactInteraction: ContactInteractionUnion | ConversationNote,
) => {
  return 'direction' in contactInteraction
    ? contactInteraction.direction
    : contactInteraction.__typename === 'ContactInteractionFacebookAd'
    ? 'INBOUND'
    : 'OUTBOUND';
};

export const getFileSizeLabel = (byteValue: number) => {
  return `${(KB_IN_A_BYTE * byteValue).toFixed(2)} KB`;
};

export const getConversationItemUserId = (
  interaction: ConversationItemUnion,
): number | null => {
  return interaction.__typename === 'ConversationNote'
    ? interaction.createdBy.id
    : interaction.participatingUser?.id ?? null;
};

export const formatAudioDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);

  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
};
