import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  LggDropdownButtonWithoutOverlay,
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
} from 'src/components/general/button/dropdown-button';
import {
  DrawerSelectableOption,
  SelectableOptionsDrawer,
} from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const SelectContainer = styled(FlexColumn)`
  ${up('md')} {
    align-items: center;
    flex-direction: row;
  }

  & + & {
    margin-top: 18px;

    ${up('md')} {
      margin-top: 15px;
    }
  }
`;

const SelectText = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 13px;
  margin-bottom: 1px;
  margin-right: 2px;
  text-align: left;

  ${up('md')} {
    flex: 1;
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    line-height: 17px;
  }
`;

const SelectExcerpt = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 13px;
  text-align: left;

  ${up('md')} {
    font-family: ${({ theme }) => theme.font.regular};
  }
`;

const ResourceSelector = styled(LggDropdownButtonWithoutOverlay)`
  flex: 1;
  min-height: 34px;
  padding: 0 12px;

  .dropdown-label {
    color: ${({ theme }) => theme.colors.storm};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 17px;
    opacity: 0.8;
    text-align: left;
  }

  .lgg-icon {
    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  ${up('md')} {
    max-width: 160px;
  }
`;

const LabelContainer = styled(FlexRow)`
  ${up('md')} {
    flex: 1;
    flex-direction: column;
  }
`;

type PhoneNumberSelectProps = {
  label: string;
  excerpt?: string;
  options: DrawerSelectableOption[];
  selectedResource: string;
};

export const PhoneNumberSelect = memo<PhoneNumberSelectProps>(
  ({ label, options, selectedResource, excerpt }) => {
    const visibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['contacts']);
    const dropdownButton = (
      <ResourceSelector
        className="resource-selector"
        onClick={visibilityHandler.show}
        isActive={visibilityHandler.visible}
        size="small"
        variant="default"
        children={options.find((option) => option.value === selectedResource)?.label}
      />
    );

    return (
      <>
        <SelectContainer>
          <LabelContainer>
            <SelectText>{label}</SelectText>
            {excerpt && <SelectExcerpt>{excerpt}</SelectExcerpt>}
          </LabelContainer>
          {breakpointUpMd ? (
            <LggSelectableOptionsDropdownButtonWithCustomTrigger
              options={options}
              children={dropdownButton}
              selectedValue={selectedResource}
              size="small"
              variant="default"
              visibilityHandler={visibilityHandler}
            />
          ) : (
            <>
              {dropdownButton}
              <SelectableOptionsDrawer
                onClose={visibilityHandler.close}
                visible={visibilityHandler.visible}
                options={options}
                selectedValue={selectedResource}
                title={t('contacts:callContactModal.selectNumber')}
              />
            </>
          )}
        </SelectContainer>
      </>
    );
  },
);
