/* eslint-disable */

import { memo, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Upload } from 'antd';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import {
  Mutation,
  MutationConversationGenerateS3PresignedPostArgs,
  MutationConversationValidateAttachmentsArgs,
  Query,
} from '@lgg/isomorphic/types/__generated__/graphql';

const GET_CONTACT_INTERACTION_CHANNELS = gql`
  {
    contactInteractionChannels {
      slug
      fileAttachmentConstraints {
        maxIndividualFileSizeBytes
        totalSizeBytes
        maxFiles
        allowedTypes {
          attachmentType
          mimeTypes
        }
      }
    }
  }
`;

const VALIDATE_FILE_ATTACHMENTS = gql`
  mutation ConversationValidateAttachments(
    $channel: ContactInteractionChannelSlug!
    $attachments: [ConversationValidateFileAttachmentInput!]!
  ) {
    conversationValidateAttachments(channel: $channel, attachments: $attachments) {
      ... on HasError {
        hasError
      }
      ... on ConversationValidateAttachmentsError {
        noChannelConfig
        maxFilesExceeded
        totalSizeExceeded
        fileErrors {
          ref
          individualFileSizeExceeded
          attachmentTypeNotAllowed
          mimeTypeNotAllowed
        }
      }
    }
  }
`;

const GENERATE_S3_PRESIGNED_POST = gql`
  mutation ConversationGenerateS3PresignedPost(
    $channel: ContactInteractionChannelSlug!
    $attachment: ConversationValidateFileAttachmentInput!
  ) {
    conversationGenerateS3PresignedPost(channel: $channel, attachment: $attachment) {
      url
      fields {
        key
        value
      }
    }
  }
`;

type PresignedPost = {
  url: string;
  fields: { key: string; value: string }[];
};

async function uploadFile(file: File, presignedPost: PresignedPost) {
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  for (const { key, value } of presignedPost.fields) {
    formData.append(key, value);
  }
  formData.append('file', file);

  const { headers } = await axios.post(presignedPost.url, formData);

  // const res = await window.fetch(presignedPost.url, {
  //   method: 'POST',
  //   body: formData,
  // });

  console.log('headers', headers);

  const location = headers['location']; // get the final url of our uploaded image
  return location ? decodeURIComponent(location) : null;
}

const testChannel = 'FACEBOOK_MESSENGER';

export const DevFileAttachments = memo(() => {
  const { loading, data } = useQuery<Pick<Query, 'contactInteractionChannels'>>(
    GET_CONTACT_INTERACTION_CHANNELS,
  );
  const [validateFileAttachments] = useMutation<
    Pick<Mutation, 'conversationValidateAttachments'>,
    MutationConversationValidateAttachmentsArgs
  >(VALIDATE_FILE_ATTACHMENTS);
  const [generateS3PresignedPost] = useMutation<
    Pick<Mutation, 'conversationGenerateS3PresignedPost'>,
    MutationConversationGenerateS3PresignedPostArgs
  >(GENERATE_S3_PRESIGNED_POST);

  const [uploadedAttachments, setUploadedAttachments] = useState<
    {
      ref: string;
      filename: string;
      url: string;
      attachmentType: any;
      mimeType: string;
      sizeBytes: number;
    }[]
  >([]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data?.contactInteractionChannels) {
    return null;
  }

  console.log('current attachments', uploadedAttachments);

  const fileAttachmentConstraints = data.contactInteractionChannels.find(
    (c) => c.slug === testChannel,
  )?.fileAttachmentConstraints;

  if (!fileAttachmentConstraints) {
    return null;
  }

  const contactId = 'contact';

  return (
    <div>
      dev file attachments
      {fileAttachmentConstraints.allowedTypes.map(({ attachmentType, mimeTypes }) => (
        <Upload
          key={attachmentType}
          // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
          accept={mimeTypes.join(',')}
          customRequest={async ({ file, onProgress, onSuccess, onError, data }) => {
            console.log('custom request', typeof file);
            console.log('custom request data', data);
            if (file instanceof File) {
              const { name: filename, size: sizeBytes, type: mimeType } = file;
              const ref = uuid();

              const newFileAttachmentData = {
                ref,
                filename,
                attachmentType,
                mimeType,
                sizeBytes,
              };

              // validate all uploaded files + new one
              {
                const { data, errors } = await validateFileAttachments({
                  variables: {
                    channel: testChannel,
                    attachments: [
                      ...uploadedAttachments.map(
                        ({ ref, filename, attachmentType, mimeType, sizeBytes }) => ({
                          ref,
                          filename,
                          attachmentType,
                          mimeType,
                          sizeBytes,
                        }),
                      ),
                      // new file
                      {
                        ...newFileAttachmentData,
                        // sizeBytes: 2147483647, // max 32-bit int,
                      },
                    ],
                  },
                });

                console.log('errors', errors);

                if (!data?.conversationValidateAttachments) {
                  return null;
                }

                console.log(
                  'conversationValidateAttachments hasError',
                  data.conversationValidateAttachments.hasError,
                );

                if (
                  data.conversationValidateAttachments.__typename ===
                  'ConversationValidateAttachmentsError'
                ) {
                  const {
                    noChannelConfig,
                    maxFilesExceeded,
                    totalSizeExceeded,
                    fileErrors,
                  } = data.conversationValidateAttachments;

                  const thisFileErrors = fileErrors?.find(
                    (fileError) => fileError.ref === ref,
                  );

                  console.log({
                    noChannelConfig,
                    maxFilesExceeded,
                    totalSizeExceeded,
                    fileErrors: thisFileErrors ?? {
                      individualFileSizeExceeded: false,
                      attachmentTypeNotAllowed: false,
                      mimeTypeNotAllowed: false,
                    },
                  });

                  return onError?.(new Error('oops'));
                }
              }

              {
                const { data, errors } = await generateS3PresignedPost({
                  variables: {
                    channel: testChannel,
                    attachment: {
                      ...newFileAttachmentData,
                      // sizeBytes: 2147483647, // max 32-bit int,
                      // sizeBytes: 565, // cause content-length-range condition to fail
                      // mimeType: 'image/png', // cause content-type condition to fail
                    },
                  },
                });
                console.log('data', data, 'errors', errors);
                if (errors || !data?.conversationGenerateS3PresignedPost) {
                  return onError?.(new Error('oops 2'));
                }

                try {
                  const uploadedUrl = await uploadFile(
                    file,
                    data.conversationGenerateS3PresignedPost,
                  );
                  console.log('uploaded url', uploadedUrl);

                  onSuccess?.({ uploadedUrl, attachmentType });
                } catch (error) {
                  return onError?.(new Error('oops 3'));
                }
              }
            }
          }}
          onChange={(info) => {
            console.log('onchange', info);
            setUploadedAttachments(
              info.fileList
                .filter(({ status }) => status === 'done')
                .map((entry) => ({
                  ref: entry.uid,
                  url: entry.response!.uploadedUrl,
                  filename: entry.name,
                  attachmentType: entry.response!.attachmentType,
                  mimeType: entry.type!,
                  sizeBytes: entry.size!,
                })),
            );
          }}
        >
          <Button icon={<UploadOutlined />}>Upload {attachmentType}</Button>
        </Upload>
      ))}
    </div>
  );
});
