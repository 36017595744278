import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import { Virtuoso } from 'react-virtuoso';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { NotificationFeedItemUnion } from '@lgg/isomorphic/types/__generated__/graphql';
import { NotificationItem } from 'src/components/domain/notifications/items/notification-item';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { SessionService } from 'src/services/session.service';
import { sortNotificationFeedItems } from './helpers/sort-notification-feed-items';

type NotificationsListProps = {
  onLoadMore: VoidFunction;
  loadingMore: boolean;
  notifications: NotificationFeedItemUnion[];
  handleNotificationUpdated: (notification: NotificationFeedItemUnion) => Promise<void>;
  firstItemIndex: number;
  hasNewNotification?: boolean;
  areNotificationsVisible?: boolean;
};

export const NotificationsList = memo<NotificationsListProps>(
  ({
    notifications,
    handleNotificationUpdated,
    onLoadMore,
    loadingMore,
    firstItemIndex,
    hasNewNotification,
    areNotificationsVisible,
  }) => {
    const userId = useServiceSelector(SessionService, (s) => s.data.user.id);
    const hasNewNotificationRef = useRef(hasNewNotification);
    const [virtuosoKey, setVirtuosoKey] = useState(Math.random());

    useEffect(() => {
      setTimeout(() => {
        const shouldScrollToTop =
          !areNotificationsVisible &&
          !hasNewNotificationRef.current &&
          hasNewNotification;

        if (shouldScrollToTop) {
          setVirtuosoKey(Math.random());
        }

        hasNewNotificationRef.current = hasNewNotification;
      }, 500);
    }, [areNotificationsVisible, hasNewNotification]);

    if (notifications?.length) {
      const sortedNotifications = sortNotificationFeedItems(notifications);

      return (
        <Virtuoso
          key={virtuosoKey}
          data={sortedNotifications}
          endReached={onLoadMore}
          firstItemIndex={firstItemIndex}
          components={{
            Footer: () => <ListLoadingItem visible={loadingMore} />,
          }}
          itemContent={(index, notification) => {
            return (
              <NotificationItem
                userId={userId}
                notification={notification}
                handleNotificationUpdated={handleNotificationUpdated}
              />
            );
          }}
        />
      );
    }

    return <NotificationEmptyListMessage />;
  },
);

const MessageContainer = styled(FlexColumn)`
  align-items: center;
  margin: 53px 20px 20px;
`;

const MessageIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 50%;
  margin-bottom: 20px;
  padding: 30px;
  width: 90px;

  svg {
    height: 30px;
    width: 30px;

    ${up('md')} {
      height: 31px;
      width: 31px;
    }

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const MessageTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  letter-spacing: -0.36px;
  line-height: 22px;
  margin: 0 0 10px;
  text-align: center;
`;

const MessageSubTitle = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  text-align: center;
`;

export const NotificationEmptyListMessage = memo(() => {
  const { t } = useTranslation(['notifications']);
  return (
    <MessageContainer data-lgg-id="notification-empty-list-message">
      <MessageIcon type="circularCheck" />
      <MessageTitle>{t('notifications:list.emptyState.title')}</MessageTitle>
      <MessageSubTitle>{t('notifications:list.emptyState.subtitle')}</MessageSubTitle>
    </MessageContainer>
  );
});
