import React from 'react';
import c from 'classnames';
import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { labelTextStyle } from 'src/theme/sub-themes/inputs-theme';

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  leadingIcon?: string;
  error?: string | boolean;
  reserveErrorArea?: boolean;
  containerStyles?: React.CSSProperties;
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextInput = styled.input<{ hasLeadingIcon?: boolean }>`
  border-radius: 4px;
  caret-color: ${({ theme }) => theme.colors.lightBlue};
  height: 38px;
  outline: none;
  padding: 11px;
  padding-left: ${({ hasLeadingIcon }) => (hasLeadingIcon ? '37px' : '11px')};
  width: 100%;
  transition: border 150ms, box-shadow 150ms;
  ${({ theme }) => theme.inputsTheme.textStyle};
  ${({ theme }) => theme.inputsTheme.border};

  &:hover {
    ${({ theme }) => theme.inputsTheme.border};
  }

  &:focus {
    ${({ theme }) => theme.inputsTheme.focusBorder};
  }

  ${InputContainer}.input-error & {
    ${({ theme }) => theme.inputsTheme.errorBorder};
    padding-right: 22px;
  }

  &::placeholder {
    ${({ theme }) => theme.inputsTheme.placeholderStyle};
  }
`;

const Label = styled.span`
  ${labelTextStyle};
`;

const Error = styled.span`
  ${({ theme }) => theme.inputsTheme.errorStyle};
  margin-top: 1px;
  min-height: 14px;
  opacity: 0;

  ${InputContainer}.input-error & {
    opacity: 1;
  }
`;

const Dot = styled.div`
  background-color: ${({ theme }) => theme.colors.complementaryColor6};
  border-radius: 50%;
  height: 6px;
  position: absolute;
  right: 14px;
  top: calc(50% - 3px);
  width: 6px;
`;

const LeadingIcon = styled(Icon)`
  position: absolute;
  left: 11px;
`;

const ClearIcon = styled(Icon)`
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const UnDecoratedTextInput = React.forwardRef<
  HTMLInputElement,
  Omit<TextInputProps, 'error' | 'label' | 'reserveErrorArea'> & {
    onClear?: VoidFunction;
  }
>(({ value, leadingIcon, className, onClear, ...rest }, ref) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
      className={className}
    >
      <StyledTextInput
        value={value || ''}
        {...rest}
        hasLeadingIcon={Boolean(leadingIcon)}
      />
      {leadingIcon && <LeadingIcon type={leadingIcon} />}
      {value && onClear && (
        <ClearIcon type="close" onClick={onClear} lggTestId="text-input-clear-icon" />
      )}
    </div>
  );
});

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, error, value, reserveErrorArea = true, containerStyles, ...rest }, ref) => {
    const hasError = Boolean(error);

    return (
      <InputContainer
        style={containerStyles}
        className={c({
          'input-error': hasError,
        })}
      >
        <Label>{label}</Label>
        <div style={{ position: 'relative', width: '100%' }}>
          <StyledTextInput className="lgg-text-input" value={value || ''} {...rest} />
          {hasError && <Dot />}
        </div>
        {(reserveErrorArea || error) && <Error>{error}</Error>}
      </InputContainer>
    );
  },
);
