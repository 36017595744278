import React, { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  ContactInteractionUnion,
  ContactInteractionDirection,
  ContactInteractionPhoneCall,
  User,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import {
  ContactInteractionDetailsContextMenu,
  ContactInteractionDetailsMenuTitle,
} from 'src/components/domain/contact-interaction/contact-interaction-detail-item';
import { InProgressCallIndicator } from 'src/components/domain/contact-interaction/in-progress-call-indicator';
import { ManualInteractionIcon } from 'src/components/domain/contact-interaction/manual-interaction-icon';
import { PopoverV2 } from 'src/components/general/display/popover';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useUrls } from 'src/hooks/use-urls';
import { getActivityIcon } from '../helpers';
import { ActivityIconWithDirection } from './activity-icon-with-direction';

const ActivityTitleContainer = styled(FlexRow)`
  position: relative;
  width: 100%;

  ${up('md')} {
    align-items: center;
  }
`;

const ActivityDescription = styled.span`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  white-space: break-spaces;
  text-indent: 19px;

  & > span {
    line-height: 14px;
  }

  ${up('md')} {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-indent: unset;
    line-height: 17px;

    & > * {
      line-height: 15px;
    }
  }
`;

const MobileActivityIcon = styled(Icon)`
  position: absolute;
  top: 1px;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const StrongText = styled.span<{ danger?: boolean }>`
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.storm};

  ${({ danger, theme }) =>
    danger &&
    `
    color: ${theme.colors.secondaryCoral80};
  `}
`;

const ActivityDescriptionWrapper = styled.span`
  cursor: pointer;
  line-height: 18px;

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.storm};
  }

  ${up('md')} {
    line-height: 15px;

    &:hover {
      &,
      ${StrongText} {
        color: ${({ theme }) => theme.colors.gogo};
      }
    }
  }
`;

const InteractionBadgeContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 9px;
  border: solid 1px rgba(152, 169, 188, 0.25);
  height: 18px;
  margin-right: 10px;
  max-width: 30%;
  padding: 2px 5px;
`;

const InteractionBadgeIcon = styled(Icon)`
  margin-right: 5px;
  svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const InteractionBadgeText = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.22px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
`;

const ActivityBadgesContainer = styled(FlexRow)`
  margin-top: 8px;

  ${up('md')} {
    margin-top: 4px;
  }
`;

const TitleContentContainer = styled(FlexColumn)`
  width: 100%;
`;

type InteractionInfoBadgeProps = {
  icon: string;
  text: string;
  ['data-lgg-id']: string;
};

const InteractionInfoBadge = ({ icon, text, ...rest }: InteractionInfoBadgeProps) => {
  return (
    <InteractionBadgeContainer {...rest}>
      <InteractionBadgeIcon type={icon} />
      <InteractionBadgeText>{text}</InteractionBadgeText>
    </InteractionBadgeContainer>
  );
};

type ContactInteractionDetailsPopoverProps = {
  title: string;
  details: JSX.Element;
  children?: JSX.Element;
};

const ContactInteractionDetailsPopover = ({
  children,
  details,
  title,
}: ContactInteractionDetailsPopoverProps) => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const popoverTrigger = <span data-lgg-id="activity-popover-trigger">{children}</span>;

  return breakpointUpMd ? (
    <PopoverV2
      trigger={['hover']}
      placement="topLeft"
      align={{
        offset: [0, 3],
      }}
      overlayClassName="activity-details-popover"
      content={
        <ContactInteractionDetailsContextMenu data-lgg-id="activity-details-popover">
          <ContactInteractionDetailsMenuTitle>{title}</ContactInteractionDetailsMenuTitle>
          {details}
        </ContactInteractionDetailsContextMenu>
      }
    >
      {popoverTrigger}
    </PopoverV2>
  ) : (
    popoverTrigger
  );
};

type ActivityTitleProps = {
  contactInteraction: ContactInteractionUnion;
  contactInteractionDetails: JSX.Element;
  direction?: ContactInteractionDirection;
};

export const ActivityTitle = memo<ActivityTitleProps>(
  ({ contactInteraction, contactInteractionDetails, direction }) => {
    const isUnansweredCall =
      contactInteraction.__typename === 'ContactInteractionPhoneCall' &&
      contactInteraction.dialStatus === 'UNANSWERED';
    const { t } = useTranslation(['activity', 'common']);
    const breakpointUpMd = useBreakpoint(up('md'));
    const activityIconName = getActivityIcon({
      type: contactInteraction.__typename,
      isUnansweredCall: isUnansweredCall,
    });
    const { getContactInteractionUrl } = useUrls();
    const contactInteractionUrl = getContactInteractionUrl(contactInteraction.id);

    const activityTextDescription = useMemo(() => {
      const components = { strong: <StrongText />, danger: <StrongText danger /> };
      const callInProgressLabel = <InProgressCallIndicator />;
      const getInboundAnsweredByTitle = ({
        answeredPhone,
        participatingUser,
      }:
        | ContactInteractionPhoneCall
        | (ContactInteractionPhoneCall & { participatingUser: User })) => {
        if (participatingUser) {
          return (
            <Trans
              i18nKey="activity:interaction.call.inboundAnsweredWithParticipatingUser"
              values={{
                participatingUserName: participatingUser.fullName,
              }}
              components={components}
            />
          );
        }

        if (answeredPhone) {
          return (
            <Trans
              i18nKey="activity:interaction.call.inboundAnsweredWithoutParticipatingUserButPhoneNumber"
              values={{
                phoneNumber: answeredPhone.national,
              }}
              components={components}
            />
          );
        }

        return (
          <Trans
            i18nKey="activity:interaction.call.inboundAnsweredWithoutParticipatingUser"
            components={components}
          />
        );
      };

      return matchContactInteractionExhaustive(contactInteraction, {
        phone: {
          defaultInbound: () => (
            <Trans
              i18nKey="activity:interaction.call.defaultInbound"
              components={components}
            />
          ),
          defaultOutbound: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.defaultOutbound"
              values={{ userName: participatingUser?.fullName }}
              components={components}
            />
          ),
          inboundManualWithParticipatingUser: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.inboundManualWithParticipatingUser"
              values={{
                participatingUserName: participatingUser.fullName,
              }}
              components={components}
            />
          ),
          inboundManualWithoutParticipatingUser: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundManualWithoutParticipatingUser"
              components={components}
            />
          ),
          inboundAnsweredWithParticipatingUser: getInboundAnsweredByTitle,
          inboundAnsweredWithoutParticipatingUser: getInboundAnsweredByTitle,
          inboundUnansweredWithDialStepMenu: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundUnansweredWithDialStepMenu"
              components={components}
            />
          ),
          inboundUnansweredWithDialStepMessage: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundUnansweredWithDialStepMessage"
              components={components}
            />
          ),
          inboundUnansweredWithDialStepDial: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundUnansweredWithDialStepDial"
              components={components}
            />
          ),
          inboundUnansweredWithDialStepVoicemailWithRecording: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundUnansweredWithDialStepVoicemailWithRecording"
              components={components}
            />
          ),
          inboundUnansweredWithDialStepVoicemailWithoutRecording: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundUnansweredWithDialStepVoicemailWithoutRecording"
              components={components}
            />
          ),
          inboundUnansweredWithoutDialStep: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundUnansweredWithoutDialStep"
              components={components}
            />
          ),
          inboundOtherStatusDialStepMenu: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundOtherStatusDialStepMenu"
              components={components}
            />
          ),
          inboundOtherStatusDialStepMessage: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundOtherStatusDialStepMessage"
              components={components}
            />
          ),
          inboundOtherStatusDialStepDial: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundOtherStatusDialStepDial"
              components={components}
            />
          ),
          inboundOtherStatusDialStepVoicemailWithRecording: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundOtherStatusDialStepVoicemailWithRecording"
              components={components}
            />
          ),
          inboundOtherStatusDialStepVoicemailWithoutRecording: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundOtherStatusDialStepVoicemailWithoutRecording"
              components={components}
            />
          ),
          inboundOtherStatusNoStep: () => (
            <Trans
              i18nKey="activity:interaction.call.inboundOtherStatusNoStep"
              components={components}
            />
          ),
          outboundManual: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundManual"
              components={components}
              values={{ originator: participatingUser?.fullName }}
            />
          ),
          outboundAnswered: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundAnswered"
              values={{ originator: participatingUser?.fullName }}
              components={components}
            />
          ),
          outboundWithCancelReason: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundWithCancelReason"
              components={components}
              values={{ originator: participatingUser?.fullName }}
            />
          ),
          outboundUnansweredWithBusyReason: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundUnansweredWithBusyReason"
              components={components}
              values={{ originator: participatingUser?.fullName }}
            />
          ),
          outboundUnansweredWithFailedReason: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundUnansweredWithFailedReason"
              components={components}
              values={{ originator: participatingUser?.fullName }}
            />
          ),
          outboundUnansweredWithOtherReason: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundUnansweredWithOtherReason"
              components={components}
              values={{ originator: participatingUser?.fullName }}
            />
          ),
          outboundOtherStatus: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.call.outboundOtherStatus"
              components={components}
              values={{ originator: participatingUser?.fullName }}
            />
          ),
          inboundAnsweredWithParticipatingUserInProgress: (interaction) => (
            <>
              {getInboundAnsweredByTitle(interaction)}
              {callInProgressLabel}
            </>
          ),
          inboundAnsweredWithoutParticipatingUserInProgress: (interaction) => (
            <>
              {getInboundAnsweredByTitle(interaction)}
              {callInProgressLabel}
            </>
          ),
          inboundInProgress: () => (
            <>
              <Trans
                i18nKey="activity:interaction.call.defaultInbound"
                components={components}
              />
              {callInProgressLabel}
            </>
          ),
          outboundInProgress: ({ participatingUser }) => (
            <>
              <Trans
                i18nKey="activity:interaction.call.defaultOutbound"
                values={{ originator: participatingUser?.fullName }}
                components={components}
              />
              {callInProgressLabel}
            </>
          ),
        },
        sms: {
          inboundWithParticipatingUser: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.sms.inboundWithParticipatingUser"
              values={{
                participatingUserName: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
          inboundWithoutParticipatingUser: () => (
            <Trans
              i18nKey="activity:interaction.sms.inboundWithoutParticipatingUser"
              components={components}
            />
          ),
          outbound: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.sms.outbound"
              values={{
                originator: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
        },
        facebookMessenger: {
          inboundWithParticipatingUser: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.facebookMessenger.inboundWithParticipatingUser"
              values={{
                participatingUserName: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
          inboundWithoutParticipatingUser: () => (
            <Trans
              i18nKey="activity:interaction.facebookMessenger.inboundWithoutParticipatingUser"
              components={components}
            />
          ),
          outbound: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.facebookMessenger.outbound"
              values={{
                originator: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
        },
        instagram: {
          inboundWithParticipatingUser: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.instagram.inboundWithParticipatingUser"
              values={{
                participatingUserName: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
          inboundWithoutParticipatingUser: () => (
            <Trans
              i18nKey="activity:interaction.instagram.inboundWithoutParticipatingUser"
              components={components}
            />
          ),
          outbound: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.instagram.outbound"
              values={{
                originator: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
        },
        email: {
          inboundWithParticipatingUser: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.email.inboundWithParticipatingUser"
              values={{
                participatingUserName: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
          inboundWithoutParticipatingUser: () => (
            <Trans
              i18nKey="activity:interaction.email.inboundWithoutParticipatingUser"
              components={components}
            />
          ),
          outbound: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.email.outbound"
              values={{
                originator: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
        },
        inPerson: ({ participatingUser }) => (
          <Trans
            i18nKey="activity:interaction.inPerson"
            values={{ participatingUserName: participatingUser?.fullName }}
            components={components}
          />
        ),
        facebookAd: () => (
          <Trans i18nKey="activity:interaction.facebookAd" components={components} />
        ),
        whatsapp: {
          inboundWithParticipatingUser: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.whatsapp.inboundWithParticipatingUser"
              values={{
                participatingUserName: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
          inboundWithoutParticipatingUser: () => (
            <Trans
              i18nKey="activity:interaction.whatsapp.inboundWithoutParticipatingUser"
              components={components}
            />
          ),
          outbound: ({ participatingUser }) => (
            <Trans
              i18nKey="activity:interaction.whatsapp.outbound"
              values={{
                originator: participatingUser?.fullName,
              }}
              components={components}
            />
          ),
        },
        unknown: () => (
          <Trans i18nKey="activity:interaction.unknown" components={components} />
        ),
      });
    }, [contactInteraction]);

    const { source, department, campaign } = contactInteraction;

    const showBadgeContainer = source?.name || department?.name || campaign?.name;

    return (
      <ActivityTitleContainer data-lgg-id="activity-table-cell-title">
        {breakpointUpMd ? (
          <ActivityIconWithDirection icon={activityIconName} direction={direction} />
        ) : (
          <MobileActivityIcon type={activityIconName} lggTestId="activity-icon" />
        )}
        <TitleContentContainer>
          <ActivityDescription data-lgg-id="activity-item-description">
            <ContactInteractionDetailsPopover
              title={t('activity:activityDetailsPopover.title')}
              details={contactInteractionDetails}
            >
              {breakpointUpMd ? (
                <ActivityDescriptionWrapper
                  data-lgg-id="activity-contact-interaction-link"
                  as={Link}
                  to={contactInteractionUrl}
                >
                  {activityTextDescription}
                </ActivityDescriptionWrapper>
              ) : (
                <ActivityDescriptionWrapper>
                  {activityTextDescription}
                </ActivityDescriptionWrapper>
              )}
            </ContactInteractionDetailsPopover>
            <ManualInteractionIcon contactInteraction={contactInteraction} />
          </ActivityDescription>
          {showBadgeContainer && (
            <ActivityBadgesContainer>
              {source?.name && (
                <InteractionInfoBadge
                  icon="activitySource"
                  text={source.name}
                  data-lgg-id="activity-source-badge"
                />
              )}
              {campaign?.name && (
                <InteractionInfoBadge
                  icon="activityCampaign"
                  text={campaign.name}
                  data-lgg-id="activity-campaign-badge"
                />
              )}
              {department?.name && (
                <InteractionInfoBadge
                  icon="activityDepartment"
                  text={department.name}
                  data-lgg-id="activity-department-badge"
                />
              )}
            </ActivityBadgesContainer>
          )}
        </TitleContentContainer>
      </ActivityTitleContainer>
    );
  },
);
