import React, { memo, useContext, useMemo } from 'react';
import AntDrawer, { DrawerProps } from 'antd/lib/drawer';
import styled from 'styled-components';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';
import { Expand } from 'src/components/layout/expand';
import { FlexColumn } from 'src/components/layout/flex-column';
import {
  SafeAreaBottom,
  SafeAreaInsetsContext,
} from 'src/components/providers/safe-area-insets-provider';

const StyledDrawerHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  display: flex;
  height: 70px;
  padding: 0 20px;
`;

const HeaderTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 25px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const HeaderSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;

const HeaderCloseIcon = styled(Icon)`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

export type DrawerBaseOption = {
  label: string;
  subTitle?: string;
  icon?: string | React.ReactNode;
  trailing?: React.ReactNode;
  'data-lgg-id'?: string;
  disabled?: boolean;
};

const StyledDrawer = styled(AntDrawer)<{ mode: 'options' | 'custom' }>`
  &.ant-drawer {
    .ant-drawer-mask {
      background-color: ${({ theme }) => theme.colors.storm + 'b3'};
    }

    .ant-drawer-content {
      border-radius: 6px 6px 0 0;

      .ant-drawer-header {
        padding: 0;

        .ant-drawer-close {
          display: none;
        }
      }

      .ant-drawer-body {
        padding: ${({ mode }) => (mode === 'options' ? '10px 0 20px;' : '0;')};
      }

      .ant-drawer-footer {
        border: none;
        padding: 0;
      }
    }
  }
`;

export type BottomDrawerBase = {
  visible: boolean;
  title: string;
  onClose: VoidFunction;
  trailing?: React.ReactNode;
  leading?: React.ReactNode;
  zIndex?: number;
  className?: string;
};

export const useBottomDrawerHeight = (props: { fullHeight?: boolean }) => {
  const { fullHeight } = props;
  const insets = useContext(SafeAreaInsetsContext);

  return useMemo(() => {
    if (fullHeight) {
      return insets ? `calc(100% - ${insets.top}px - 5px)` : 'calc(100% - 5px)';
    }

    return 'auto';
  }, [fullHeight, insets]);
};

export type BottomDrawerProps = Omit<DrawerProps, 'title' | 'onClose'> &
  BottomDrawerBase & {
    children?: React.ReactNode;
    fullHeight?: boolean;
    addScrollbarToBody?: boolean;
    mode?: 'options' | 'custom';
    subTitle?: string | React.ReactNode;
    showCloseIcon?: boolean;
    showHeader?: boolean;
  };

export const BottomDrawer = memo<BottomDrawerProps>(
  ({
    title,
    subTitle,
    children,
    onClose,
    leading,
    trailing,
    fullHeight,
    addScrollbarToBody = true,
    showCloseIcon = true,
    showHeader = true,
    mode = 'custom',
    footer,
    ...rest
  }) => {
    const height = useBottomDrawerHeight({ fullHeight });

    const footerWithSafeInsets = useMemo(() => {
      if (footer) {
        return (
          <>
            {footer}
            <SafeAreaBottom />
          </>
        );
      }

      return null;
    }, [footer]);

    return (
      <StyledDrawer
        {...rest}
        mode={mode}
        push={false}
        height={height}
        onClose={onClose}
        closable={true}
        footer={footerWithSafeInsets}
        title={
          showHeader ? (
            <StyledDrawerHeader onClick={(e) => e.stopPropagation()}>
              {leading}
              <FlexColumn style={{ minWidth: 0 }}>
                <HeaderTitle>{title}</HeaderTitle>
                {subTitle && <HeaderSubTitle>{subTitle}</HeaderSubTitle>}
              </FlexColumn>
              <Expand />
              {trailing}
              {showCloseIcon && <HeaderCloseIcon onClick={onClose} type="close" />}
            </StyledDrawerHeader>
          ) : null
        }
        closeIcon={null}
        placement="bottom"
      >
        {addScrollbarToBody ? <Scrollbar>{children}</Scrollbar> : children}
      </StyledDrawer>
    );
  },
);
