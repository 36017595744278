import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Mutation,
  MutationUpdatePasswordArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  PasswordManagementFormValues,
  passwordManagementSchema,
} from 'src/components/domain/users/components/profile-settings-modal/form-validation-schemas';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-profile-settings';
import { UPDATE_PASSWORD_MUTATION } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';

export const usePasswordManagementForm = () => {
  const { showCancelConfirm, visibilityHandler } = useProfileSettings();

  const [updatePassword] = useMutation<
    Pick<Mutation, 'updatePassword'>,
    MutationUpdatePasswordArgs
  >(UPDATE_PASSWORD_MUTATION);

  const form = useForm<PasswordManagementFormValues>({
    resolver: zodResolver(passwordManagementSchema),
  });

  const {
    formState: { isDirty },
    reset,
  } = form;

  const showNotification = useShowNotification();

  const { t } = useTranslation(['user']);

  const onSubmit: SubmitHandler<PasswordManagementFormValues> = useCallback(
    ({ confirmNewPassword, ...rest }) => {
      void updatePassword({
        variables: {
          input: rest,
        },
        onCompleted: () => {
          visibilityHandler.close();
          showNotification({
            type: 'success',
            title: t('user:profileSettings.sections.passwordManagement.toasts.success'),
          });
        },
        onError: () => {
          showNotification({
            type: 'error',
            title: t('user:profileSettings.sections.passwordManagement.toasts.error'),
          });
        },
      });
    },
    [t, updatePassword, visibilityHandler, showNotification],
  );

  const onReset = () => showCancelConfirm(isDirty, reset);

  return { form, onReset, onSubmit };
};
