import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Query, QueryUserArgs, User } from '@lgg/isomorphic/types/__generated__/graphql';
import { GET_USER_QUERY } from 'src/components/domain/users/components/profile-settings-modal/operations';

export const useGetUser = (userId: number): undefined | User => {
  const { data, loading, error } = useQuery<Pick<Query, 'user'>, QueryUserArgs>(
    GET_USER_QUERY,
    {
      variables: {
        id: userId.toString(),
      },
    },
  );

  return useMemo(() => {
    if (loading || error || !data?.user) {
      return undefined;
    }

    return data.user;
  }, [data, loading, error]);
};
