import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguagePreferenceForm } from 'src/components/domain/users/components/profile-settings-modal/components/language-preference-form';
import { PasswordManagementForm } from 'src/components/domain/users/components/profile-settings-modal/components/password-management-form';
import { PersonalInformationForm } from 'src/components/domain/users/components/profile-settings-modal/components/personal-information-form';
import { ProfileSettingsContext } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import { Icon } from 'src/components/general/icon';

export type SettingsOptionsSection = {
  icon: ReactElement;
  title: string;
  content: ReactElement;
};

export type SettingsOptions = {
  personalInformation: SettingsOptionsSection;
  languagePreference: SettingsOptionsSection;
  passwordManagement: SettingsOptionsSection;
  menu?: SettingsOptionsSection;
};

export type SettingsOptionsKeys = keyof SettingsOptions;

export const useProfileSettings = (initialView?: SettingsOptionsKeys) => {
  const context = useContext(ProfileSettingsContext);

  if (context === undefined) {
    throw new Error('useProfileSettings must be used within a ProfileSettingsProvider');
  }

  const { visibilityHandler, currentUser, contextHolder, showCancelConfirm } = context;

  const [currentView, setCurrentView] = useState<SettingsOptionsKeys | undefined>(
    initialView,
  );

  const { t } = useTranslation(['user']);

  const formId = `${currentView}Form`;

  const settingsOptions: SettingsOptions = useMemo(
    () => ({
      personalInformation: {
        icon: <Icon type="contact" />,
        title: t('user:profileSettings.options.personalInformation'),
        content: <PersonalInformationForm formId="personalInformationForm" />,
      },
      languagePreference: {
        icon: <Icon type="world" />,
        title: t('user:profileSettings.options.languagePreference'),
        content: <LanguagePreferenceForm formId="languagePreferenceForm" />,
      },
      passwordManagement: {
        icon: <Icon type="lock" />,
        title: t('user:profileSettings.options.passwordManagement'),
        content: <PasswordManagementForm formId="passwordManagementForm" />,
      },
    }),
    [t],
  );

  return {
    visibilityHandler,
    currentUser,
    currentView,
    setCurrentView,
    contextHolder,
    formId,
    settingsOptions,
    showCancelConfirm,
  };
};
