import React, { MouseEventHandler, memo, useCallback } from 'react';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { DrawerOption } from 'src/components/general/drawer/bottom/options-bottom-drawer';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { AdaptiveOptionItem } from 'src/components/general/items/adaptive-option-item';
import { SafeAreaBottom } from 'src/components/providers/safe-area-insets-provider';

const OptionsDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.porcelain};
  height: 1px;
  margin: 10px 0;
  width: 100%;
`;

type BottomDrawerItemsRendererProps = {
  options: Array<DrawerSelectableOption | DrawerOption>;
  onClose: VoidFunction;
  selectedValue: string | null;
  iconColorOverride?: string;
};

export const BottomDrawerItemsRenderer = memo<BottomDrawerItemsRendererProps>(
  ({ options, onClose, selectedValue, iconColorOverride }) => {
    const handleOnClick = useCallback(
      (e: any, onClick: MouseEventHandler | undefined) => {
        onClick?.(e);
        onClose();
      },
      [onClose],
    );
    const groupByEnabledOptions = groupBy(
      options.map((option) => ({
        ...option,
        disabled: option.disabled ?? false,
      })),
      'disabled',
    );

    const hasDisabledOptions = groupByEnabledOptions['true']?.length > 0;
    const optionsRenderer = useCallback(
      (options: Array<DrawerSelectableOption | DrawerOption>) => {
        return options.map((option) => {
          if ('value' in option) {
            const { onClick, value, ...rest } = option;

            return (
              <AdaptiveOptionItem
                key={value}
                onClick={(e) => handleOnClick(e, onClick)}
                selected={selectedValue ? selectedValue === value : false}
                iconColorOverride={iconColorOverride}
                {...rest}
              />
            );
          } else {
            const { label, onClick, ...rest } = option;
            return (
              <AdaptiveOptionItem
                key={label}
                onClick={(e) => handleOnClick(e, onClick)}
                label={label}
                iconColorOverride={iconColorOverride}
                {...rest}
              />
            );
          }
        });
      },
      [handleOnClick, iconColorOverride, selectedValue],
    );

    return (
      <div onClick={(e) => e.stopPropagation()}>
        {groupByEnabledOptions['false'] &&
          optionsRenderer(groupByEnabledOptions['false'])}
        {hasDisabledOptions && <OptionsDivider />}
        {groupByEnabledOptions['true'] && optionsRenderer(groupByEnabledOptions['true'])}
        <SafeAreaBottom />
      </div>
    );
  },
);
